import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import { IoChevronForwardSharp } from "react-icons/io5";
import {
  error50,
  error900,
  netural400,
  neturalBlack,
  secondary50,
  secondary500,
  success50,
  success900,
} from "../../utils/colors";
import { Link } from "react-router-dom";
import CustomLabel from "../shared/label/custom-label.component";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FETCH_USER_SUBSCRIPTION_PRODUCT_LIST } from "../../graphQL/subscription/subscription";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import Footer from "../home-page-footer/home-page-footer.component";

const SubscriptionList = () => {
  const resetFilterSort = () => {};
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);

  useEffect(() => {
    fetchUserSubscribeProductList();
  }, []);

  const [fetchUserSubscribeProductList, { loading: loadingSubscriptionList }] =
    useLazyQuery(FETCH_USER_SUBSCRIPTION_PRODUCT_LIST, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        //console.log("Subscription list", data.fetchUserSubscribeProductList);
        if (data && data.fetchUserSubscribeProductList) {
          const userSubscriptionList = data.fetchUserSubscribeProductList;
          setUserSubscriptionList(userSubscriptionList);
        }
      },
    });

  return loadingSubscriptionList ? (
    <LoadingSpinner overlay />
  ) : (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={10} sm={12} xs={12}>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={resetFilterSort}
                  type={"subscriptions"}
                />
                <PageTitle title={"Subscriptions"} />
              </div>
            </Col>
            {!loadingSubscriptionList && userSubscriptionList.length === 0 ? (
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="scrollable-product-column"
                style={{
                  height: "60vh",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: neturalBlack,
                  }}
                >
                  No subscription added yet.
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    color: netural400,
                    textAlign: "center",
                    marginTop: '2%'
                  }}
                >
                  Shop products with subscription to get recurring orders.
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    color: netural400,
                  }}
                >
                  Browse our {""}
                  <Link to={`/products`} style={{ color: netural400 }}>
                    list of products.
                  </Link>
                </CustomLabel>
              </Col>
            ) : (
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="scrollable-product-column"
                style={{ height: "80vh", overflowY: "auto" }}
              >
                {userSubscriptionList?.map((list, index) => {
                  return (
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        paddingTop: "2%",
                        paddingBottom: "2%",
                        borderBottom:
                          userSubscriptionList.length - 1 !== index
                            ? "solid 1px #dadada"
                            : "solid 0px #dadada",
                      }}
                      key={index}
                    >
                      <div style={{ display: "flex" }}>
                        <Col xl={11} lg={11} md={11} sm={11} xs={11}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <CustomLabel
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: neturalBlack,
                                marginTop: "0.5%",
                              }}
                            >
                              {list.productTitle}
                            </CustomLabel>
                            {list.variantCombination && (
                              <CustomLabel
                                style={{
                                  fontSize: "16px",
                                  color: netural400,
                                }}
                              >
                                {list.variantCombination}
                              </CustomLabel>
                            )}
                            <CustomLabel
                              style={{
                                fontSize: "16px",
                                color: netural400,
                                marginTop: "0.5%",
                              }}
                            >
                              Created on {list.createdAt}
                            </CustomLabel>
                            <Col
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              style={{ marginTop: "0.5%" }}
                            >
                              <CustomLabel
                                style={{
                                  color:
                                    list.status === "active"
                                      ? success900
                                      : list.status === "cancelled"
                                      ? error900
                                      : secondary500,
                                  fontSize: "16px",
                                  backgroundColor:
                                    list.status === "active"
                                      ? success50
                                      : list.status === "cancelled"
                                      ? error50
                                      : secondary50,
                                  borderRadius: "12px",
                                  paddingLeft: "4%",
                                  paddingRight: "4%",
                                  marginTop: "1%",
                                  textTransform: "capitalize",
                                }}
                              >
                                {list.status}
                              </CustomLabel>
                            </Col>
                          </div>
                        </Col>
                        <Col
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <Link to={`details/${btoa(list._id)}`}>
                            <IoChevronForwardSharp
                              size={20}
                              color={neturalBlack}
                            />
                          </Link>
                        </Col>
                      </div>
                    </Col>
                  );
                })}
              </Col>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SubscriptionList;
