import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { IoPersonOutline, IoCartOutline } from "react-icons/io5";
import { PiLockLight } from "react-icons/pi";
import { RxDividerVertical } from "react-icons/rx";
import { MdOutlineFileDownload } from "react-icons/md";
import { netural500, neturalBlack, primaryOrange } from "../../utils/colors";
import { useState } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import Logo from "../../components/logo/responsive-logo.component";
import { Link, Outlet } from "react-router-dom";
import SearchBar from "../../components/search-bar/search-bar.component";
import { isAndroid, isIOS, isDesktop } from "react-device-detect";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../utils/constants";
import DownloadAppModal from "../../components/download-app-modal/download-app-modal.component";
import LoginModal from "../../components/login/login.component";
import "react-phone-number-input/style.css";
import CartModal from "../../components/cart-modal/cart-modal.component";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  isUserLoggedIn,
  selectCurrentUser,
} from "../../store/user/user.selector";
import Logout from "../../components/logout/logout.component";
import { FETCH_CART_COUNT } from "../../graphQL/cart/cart.query";
import { useDispatch } from "react-redux";
import { setUserCartCount } from "../../store/cart/cart.action";
import { selectCartCount } from "../../store/cart/cart.selector";
import { useNavigate, useLocation } from "react-router";
import CustomLabel from "../../components/shared/label/custom-label.component";
import { MenuItem } from "react-bootstrap-typeahead";

const Navigation = () => {
  const [isCheckMobile, setIsCheckMobile] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [isShowLogout, setIsShowLogout] = useState(false);
  const [isShowCart, setIsShowCart] = useState(false);
  const [dropDownOpen, setdropDownOpen] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  const dispatch = useDispatch();
  const cartCount = useSelector(selectCartCount);
  const navigation = useNavigate();
  const [fecthCartCount] = useLazyQuery(FETCH_CART_COUNT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // console.log("SETTING REDXU", data.fetchCartCount);
      dispatch(setUserCartCount(data.fetchCartCount));
    },
  });

  const location = useLocation();
  const [isCheckoutRoute, setIsCheckoutRoute] = useState(false);

  useEffect(() => {
    // Check if the user is in the checkout route
    const isInCheckout = location.pathname.includes("/buy");

    // Set state to show/hide cart modal based on the route
    setIsCheckoutRoute(isInCheckout);
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setIsCheckMobile(true);
    } else {
      setIsCheckMobile(false);
    }
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      fecthCartCount({
        variables: {
          profileId: currentUser._id,
        },
      });
    }
  }, [navigation, isUserAuthenticated]);

  return (
    <>
      <Navbar collapseOnSelect expand="md" bg="white" variant="light">
        <Container>
          <Navbar.Brand>
            <Link
              to={"/"}
              style={{ color: neturalBlack, textDecoration: "none" }}
            >
              <Logo />
            </Link>
          </Navbar.Brand>
          {!isCheckoutRoute && (
            <>
              <div className="show-icon-mobile-header">
                {isUserAuthenticated ? (
                  <NavDropdown
                    title={
                      <img
                        src={`${process.env.REACT_APP_HOST_URL}${currentUser.avatarURL}`}
                        // src={"./images/Product-Image.png"}
                        alt="Profile"
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                          height: 30,
                          width: 30,
                        }}
                      />
                    }
                    style={{
                      marginRight: "5%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem
                      disabled
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      Hi, {currentUser.name}
                    </MenuItem>
                    <div
                      style={{ borderBottom: "1px solid rgb(218, 218,218)" }}
                    ></div>
                    <MenuItem disabled eventKey="4.2">
                      Account
                    </MenuItem>
                    <MenuItem
                      eventKey="4.3"
                      onClick={() => {
                        navigation("subscriptions");
                      }}
                    >
                      Orders
                    </MenuItem>
                    <MenuItem
                      eventKey="4.4"
                      onClick={() => {
                        setIsShowLogout(true);
                      }}
                    >
                      Log out
                    </MenuItem>
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    className="hide-icon-mobile-header"
                    onClick={() => {
                      setIsShowLogin(true);
                    }}
                  >
                    <IoPersonOutline size={22} color={neturalBlack} />
                  </Nav.Link>
                )}
                {isUserAuthenticated && (
                  <a
                    onClick={() => {
                      setIsShowCart(true);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: "3%",
                    }}
                  >
                    <IoCartOutline size={30} color={neturalBlack} />
                    {cartCount > 0 && (
                      <CustomLabel
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {cartCount}
                      </CustomLabel>
                    )}
                  </a>
                )}
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  {/* Add your navigation links here */}
                  <Nav.Link
                    // href="/products"
                    style={{ marginRight: "5%" }}
                    // onClick={() => {}}
                  >
                    <Link
                      to={"/products/category/Pets and Animals"}
                      style={{ color: neturalBlack, textDecoration: "none" }}
                    >
                      Product
                    </Link>
                  </Nav.Link>
                  <Nav.Link style={{ marginRight: "5%" }}>
                    <Link
                      to={"/services"}
                      style={{ color: neturalBlack, textDecoration: "none" }}
                    >
                      Service
                    </Link>
                  </Nav.Link>
                  {/* <Nav.Link style={{ color: neturalBlack }}>
                    <Link
                      to={"/membership"}
                      style={{ color: neturalBlack, textDecoration: "none" }}
                    >
                      Membership
                    </Link>
                  </Nav.Link> */}
                  <Nav.Link style={{ color: neturalBlack, width: "120px" }}>
                    <Link
                      to={"/aboutus"}
                      style={{ color: neturalBlack, textDecoration: "none" }}
                    >
                      About us
                    </Link>
                  </Nav.Link>
                  <Nav.Link style={{ color: neturalBlack, width: "160px" }}>
                    <Link
                      to={"/partnerwithus"}
                      style={{ color: neturalBlack, textDecoration: "none" }}
                    >
                      Partner with us
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className={isCheckMobile ? null : "justify-content-end"}
              >
                <Nav className="align-item-center">
                  {/* <Nav.Link href="#" className="hide-icon-mobile-header">
                    <IoSearchOutline
                      size={20}
                      color={neturalBlack}
                      onClick={() => {
                        setIsSearchClicked(true);
                      }}
                    />
                  </Nav.Link> */}
                  {isUserAuthenticated ? (
                    <NavDropdown
                      show={dropDownOpen}
                      onClick={() => setdropDownOpen(!dropDownOpen)}
                      title={
                        <img
                          src={`${process.env.REACT_APP_HOST_URL}${currentUser.avatarURL}`}
                          // src={"./images/Product-Image.png"}
                          alt="Profile"
                          style={{
                            objectFit: "cover",
                            borderRadius: "50%",
                            height: 40,
                            width: 40,
                          }}
                        />
                      }
                    >
                      <MenuItem
                        disabled
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: neturalBlack,
                        }}
                      >
                        Hi, {currentUser.name}
                      </MenuItem>
                      <div
                        style={{ borderBottom: "1px solid rgb(218, 218,218)" }}
                      ></div>
                      <MenuItem disabled eventKey="4.2">
                        Account
                      </MenuItem>
                      <MenuItem
                        eventKey="4.3"
                        onClick={() => {
                          setdropDownOpen(false);
                          navigation("subscriptions");
                        }}
                      >
                        Subscriptions
                      </MenuItem>
                      <MenuItem
                        eventKey="4.4"
                        onClick={() => {
                          setdropDownOpen(false);
                          setIsShowLogout(true);
                        }}
                      >
                        Log out
                      </MenuItem>
                    </NavDropdown>
                  ) : (
                    <Nav.Link
                      className="hide-icon-mobile-header"
                      onClick={() => {
                        setIsShowLogin(true);
                      }}
                    >
                      <IoPersonOutline size={22} color={neturalBlack} />
                    </Nav.Link>
                  )}
                  {isUserAuthenticated && (
                    <Nav.Link
                      className="hide-icon-mobile-header"
                      onClick={() => {
                        setIsShowCart(true);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IoCartOutline size={30} color={neturalBlack} />
                      {cartCount > 0 && (
                        <CustomLabel
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {cartCount}
                        </CustomLabel>
                      )}
                    </Nav.Link>
                  )}
                  <Nav className="hide-icon-mobile-header">
                    <RxDividerVertical size={22} color={neturalBlack} />
                  </Nav>
                  <Nav.Link
                    style={{ color: primaryOrange }}
                    onClick={() => {
                      if (isDesktop) {
                        setIsShowPopup(true);
                      } else if (isAndroid) {
                        window.location = GOOGLE_STORE_URL;
                      } else if (isIOS) {
                        window.location = APPLE_STORE_URL;
                      }
                    }}
                  >
                    Download Free App
                    <MdOutlineFileDownload size={25} color={primaryOrange} />
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
          {isCheckoutRoute && (
            <div className="d-flex align-items-center secure-checkout-container">
              <PiLockLight size={22} color={netural500} />
              <span className="ms-2 secure-checkout-label">
                Secure checkout
              </span>
            </div>
          )}
        </Container>
      </Navbar>
      {!isCheckoutRoute ? (
        <>
          <SearchBar />
          <LoginModal
            show={isShowLogin}
            onHide={() => {
              setIsShowLogin(false);
            }}
            handleSignInClickLogin={() => {
              setIsShowLogin(true);
            }}
          />
          <Logout
            show={isShowLogout}
            onHide={() => {
              setIsShowLogout(false);
            }}
          />
          <DownloadAppModal
            show={isShowPopup}
            onHide={() => {
              setIsShowPopup(false);
            }}
          />
          <CartModal show={isShowCart} onHide={() => setIsShowCart(false)} />
        </>
      ) : (
        <div style={{ borderBottom: "1px solid rgb(218, 218,218)" }}></div>
      )}
      <Outlet />
    </>
  );
};

export default Navigation;
