import { gql } from "@apollo/client";

export const FETCH_USER_SUBSCRIPTION_PRODUCT_LIST = gql`
  query fetchUserSubscribeProductList {
    fetchUserSubscribeProductList {
      _id
      productTitle
      variantCombination
      createdAt
      status
      stripeSubscriptionId
      type
    }
  }
`;

export const FETCH_SUBSCRIPTION_PRODUCT_DETAILS = gql`
  query fetchSubscribeProductDetails($subscriptionId: String) {
    fetchSubscribeProductDetails(subscriptionId: $subscriptionId) {
      _id
      subscriptionDetails {
        subscriptionId
        status
        createdOn
        completedOrders
        delivery
        minimumCycles
        maximumCycles
      }
      deliveryAddress {
        cityName
        country
        stateAndZipCode
        streetName
      }
      fullName
      cancel {
        id
        name
        reason
        cancelBy
        cancelledAt
      }
      orderScheduleInfo {
        transactionId
        nextOrderDate
        lastOrderDate
        lastOrderStatus
      }
      allowToCancelSubscription
      orderSummary {
        itemInfo {
          _id
          checkReview
          price
          quantity
          title
          uri
          variantCombination
        }
        discount
        subtotal
        taxAmount
        taxRate
        totalDiscount
        shippingCost
        total
        sellingPlan {
          sellingPlanDiscount
          discount
          planName
        }
      }
    }
  }
`;

export const FETCH_ALL_ORDER_SCHEDULE_DETAILS = gql`
  query fetchAllOrderScheduleDetails($subscriptionId: String) {
    fetchAllOrderScheduleDetails(subscriptionId: $subscriptionId) {
      id
      orderDate
      reason
      receiptURL
      status
      transactionId
    }
  }
`;

export const CANCEL_PRODUCT_SUSBCRIPTION = gql`
  mutation cancelProductSubscription(
    $cancelProductSubscriptionInput: CancelProductSubscriptionInput
  ) {
    cancelProductSubscription(
      cancelProductSubscriptionInput: $cancelProductSubscriptionInput
    ) {
      status
      message
      success
    }
  }
`;
