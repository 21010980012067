import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomLabel from "../shared/label/custom-label.component";
import {
  error50,
  error900,
  netural400,
  netural50,
  neturalBlack,
  primaryOrange,
  success50,
  success900,
} from "../../utils/colors";
import SubscriptionDetialsSection from "../subscription-details-section/subscription-details-section.component";
import OrderSummary from "../order-summary/order-summary.component";
import ShippingAddressSection from "../shipping-address-section/shipping-address-section.component";
import { IoArrowForward } from "react-icons/io5";
import CustomButton from "../button/button.component";
import Footer from "../home-page-footer/home-page-footer.component";
import { useEffect, useState } from "react";
import SubscriptionOrderDetailsModal from "../subscription-order-details-modal/subscription-order-details-modal.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CANCEL_PRODUCT_SUSBCRIPTION,
  FETCH_SUBSCRIPTION_PRODUCT_DETAILS,
} from "../../graphQL/subscription/subscription";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import CancelSubscriptionModal from "../cancel-subscription-modal/cancel-subscription-modal.component";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";

const SubscriptionDetials = () => {
  const params = useParams();
  const { subscriptionId } = params;
  const currentUser = useSelector(selectCurrentUser);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [subscriptionProductDetails, setSubscriptionProductDetails] = useState(
    {}
  );
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [orderScheduleInfo, setOrderScheduleInfo] = useState({});
  const [orderSummary, setOrderSummary] = useState({});
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [selectedCheckoutCart, setSelectedCheckoutCart] = useState({});
  const [cancelInfo, setCancleInfo] = useState(null);
  const [productName, setProductName] = useState("");
  const [showCancelSubscriptionModal, setCancelSubscriptionModal] =
    useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    getSubscriptionDetails(subscriptionId);
  }, []);

  const getSubscriptionDetails = async (subscriptionId) => {
    await fetchSubscribeProductDetails({
      variables: {
        subscriptionId: atob(subscriptionId),
      },
    });
  };

  const [
    fetchSubscribeProductDetails,
    { loading: loadingSubscriptionPRoductDetails },
  ] = useLazyQuery(FETCH_SUBSCRIPTION_PRODUCT_DETAILS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchSubscribeProductDetails) {
        const detailsList = data.fetchSubscribeProductDetails;
        const {
          subscriptionDetails,
          deliveryAddress,
          orderScheduleInfo,
          orderSummary,
          cancel,
        } = detailsList;
        // console.log("fetchSubscribeProductDetails--------------", detailsList);
        setSubscriptionProductDetails(detailsList);
        setSubscriptionDetails(subscriptionDetails);
        setCancleInfo(cancel);
        setShippingAddress(deliveryAddress);
        setOrderScheduleInfo(orderScheduleInfo);
        setOrderSummary(orderSummary);
        setCheckoutInfo({
          subTotal: orderSummary.subtotal,
          autoShipDiscount: orderSummary.sellingPlan.sellingPlanDiscount,
          discountTotal: orderSummary.totalDiscount,
          taxAmount: orderSummary.taxAmount,
          finalCheckoutTotal: orderSummary.total,
        });
        setSelectedCheckoutCart({
          shippingCost: orderSummary.shippingCost,
          taxRate: orderSummary.taxRate,
          sellingPlan: orderSummary.sellingPlan,
        });
        setProductName(orderSummary?.itemInfo[0]?.title);
      }
    },
    onError: (error) => {
      if (
        error.graphQLErrors &&
        error.graphQLErrors.some((e) => e.extensions.code != "UNAUTHENTICATED")
      ) {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      }
      navigation("/subscriptions");
    },
  });
  const [cancelProductSubscription, { loading: cancellingSubscription }] =
    useMutation(CANCEL_PRODUCT_SUSBCRIPTION, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.cancelProductSubscription) {
          //console.log("SUBSCRIPTION CANCELLED", data.cancelProductSubscription);
          getSubscriptionDetails(subscriptionId);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  return loadingSubscriptionPRoductDetails || cancellingSubscription ? (
    <LoadingSpinner overlay />
  ) : (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={10} sm={12} xs={12}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "3%",
                  }}
                >
                  <Link
                    to={"/"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: netural400,
                      textDecoration: "none",
                    }}
                  >
                    Home
                  </Link>
                  <CustomLabel style={{ marginLeft: "1%", marginRight: "1%" }}>
                    /
                  </CustomLabel>
                  <Link
                    to={"/subscriptions"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: netural400,
                      textDecoration: "none",
                    }}
                  >
                    Subscriptions
                  </Link>
                  <CustomLabel style={{ marginLeft: "1%", marginRight: "1%" }}>
                    /
                  </CustomLabel>
                  <CustomLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {productName}
                  </CustomLabel>{" "}
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SubscriptionDetialsSection
                    subscriptionDetails={subscriptionDetails}
                    cancelInfo={cancelInfo}
                  />
                </Col>
                <div
                  style={{
                    borderBottom: "1px solid rgb(218, 218,218)",
                    marginTop: "3%",
                  }}
                ></div>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ShippingAddressSection
                    shippingAddress={shippingAddress}
                    name={subscriptionProductDetails.fullName}
                  />
                </Col>
                <div
                  style={{
                    borderBottom: "1px solid #dadada",
                    marginTop: "3%",
                  }}
                ></div>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ marginTop: "3%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CustomLabel
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        Order schedule
                      </CustomLabel>
                      <Link
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: primaryOrange,
                          marginLeft: "2%",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          setShowOrderDetails(true);
                        }}
                      >
                        View orders details
                        <IoArrowForward color={primaryOrange} size={18} />
                      </Link>
                    </div>
                    <div style={{ marginTop: "3%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <CustomLabel
                          style={{
                            fontWeight: "600",
                            color: neturalBlack,
                            fontSize: "14px",
                          }}
                        >
                          Last order status
                        </CustomLabel>
                        <CustomLabel
                          style={{
                            color:
                              orderScheduleInfo.lastOrderStatus === "successful"
                                ? success900
                                : orderScheduleInfo.lastOrderStatus ===
                                    "cancelled" ||
                                  orderScheduleInfo.lastOrderStatus ===
                                    "unsucccessful"
                                ? error900
                                : neturalBlack,
                            fontSize: "14px",
                            backgroundColor:
                              orderScheduleInfo.lastOrderStatus === "successful"
                                ? success50
                                : orderScheduleInfo.lastOrderStatus ===
                                    "cancelled" ||
                                  orderScheduleInfo.lastOrderStatus ===
                                    "unsucccessful"
                                ? error50
                                : netural50,
                            borderRadius: "12px",
                            paddingLeft: "2%",
                            paddingRight: "2%",
                            textTransform: "capitalize",
                          }}
                        >
                          {orderScheduleInfo.lastOrderStatus}
                        </CustomLabel>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "2%",
                        }}
                      >
                        <CustomLabel
                          style={{
                            fontWeight: "600",
                            color: neturalBlack,
                            fontSize: "14px",
                          }}
                        >
                          Last order
                        </CustomLabel>
                        <CustomLabel
                          style={{
                            color: netural400,
                            fontSize: "14px",
                          }}
                        >
                          {orderScheduleInfo.lastOrderDate}
                        </CustomLabel>
                      </div>
                      {orderScheduleInfo.nextOrderDate && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "2%",
                          }}
                        >
                          <CustomLabel
                            style={{
                              fontWeight: "600",
                              color: neturalBlack,
                              fontSize: "14px",
                            }}
                          >
                            Next order
                          </CustomLabel>
                          <CustomLabel
                            style={{
                              color: netural400,
                              fontSize: "14px",
                            }}
                          >
                            {orderScheduleInfo.nextOrderDate}
                          </CustomLabel>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <div
                  style={{
                    borderBottom: "1px solid rgb(218, 218,218)",
                    marginTop: "3%",
                    marginBottom: "3%",
                  }}
                ></div>
                {subscriptionProductDetails.allowToCancelSubscription && (
                  <Col
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "3%",
                      marginBottom: "3%",
                    }}
                  >
                    <CustomButton
                      title={"Cancel subscription"}
                      buttonType={"secondary"}
                      handleButtonClick={() => {
                        setCancelSubscriptionModal(true);
                      }}
                    />
                  </Col>
                )}
              </Col>
              <Col
                xl={1}
                lg={1}
                md={1}
                style={{ display: "flex", justifyContent: "center" }}
              ></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                <OrderSummary
                  products={orderSummary?.itemInfo}
                  itemType={"product"}
                  pricing={checkoutInfo}
                  selectedCheckoutCart={selectedCheckoutCart}
                  promotionalCode={{}}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {showOrderDetails && (
          <SubscriptionOrderDetailsModal
            show={showOrderDetails}
            onHide={() => {
              setShowOrderDetails(false);
            }}
            subscriptionid={subscriptionProductDetails._id}
          />
        )}
        {showCancelSubscriptionModal && (
          <CancelSubscriptionModal
            show={showCancelSubscriptionModal}
            onHide={() => {
              setCancelSubscriptionModal(false);
            }}
            onSubmit={async (cancelReason) => {
              let cancelProductSubscriptionInput = {
                subscriptionId: subscriptionProductDetails._id,
                cancelByInfoInput: {
                  id: currentUser._id,
                  name: currentUser.username,
                  reason: cancelReason,
                  cancelBy: "user",
                },
              };
              setCancelSubscriptionModal(false);
              await cancelProductSubscription({
                variables: {
                  cancelProductSubscriptionInput:
                    cancelProductSubscriptionInput,
                },
              });
            }}
          />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default SubscriptionDetials;
