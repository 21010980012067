import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FETCH_HISTORY_DATA,
  REMOVE_CART_COMPANY,
  UPDATE_CONSUMER_TRANSACTION_DETAILS,
  UPDATE_ITEM_QUANTITY,
} from "../../graphQL/cart/cart.query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectAutoshipCartCheckoutByBusinessId,
  selectCartCheckoutByBusinessId,
  selectServiceCartCheckoutByBusinessId,
} from "../../store/cart/cart.selector";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import {
  decreaseUserCartCount,
  resetUserServiceCart,
  setUserCart,
} from "../../store/cart/cart.action";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { Col, Container, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { IoCheckmark } from "react-icons/io5";
import { netural400, neturalBlack, neturalGreen } from "../../utils/colors";
import OrderSummary from "../order-summary/order-summary.component";
import CustomButton from "../button/button.component";
import { isEmptyObject } from "../../utils/constants";
import Footer from "../home-page-footer/home-page-footer.component";
import CryptoJS from "crypto-js";

const PaymentStatus = () => {
  const stripe = useStripe();
  const navigation = useNavigate();
  const [message, setMessage] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("id");
  const itemType = searchParams.get("type");
  const selectedCheckoutCart = useSelector(
    selectCartCheckoutByBusinessId(businessId)
  );
  const selectedServiceCheckout = useSelector(
    selectServiceCartCheckoutByBusinessId(businessId)
  );
  const selectedCheckoutAutoShipCart = useSelector(
    selectAutoshipCartCheckoutByBusinessId(businessId)
  );
  const [products, setProducts] = useState([]);
  const [productPricing, setProductPricing] = useState({});
  const [otherPricingInfo, setOtherPricingInfo] = useState({});
  const [productAddress, setProductAddress] = useState({});
  const [orderInfo, setOrderInfo] = useState({});
  const [orderHistory, setOrderHistroy] = useState({});
  const currentUser = useSelector(selectCurrentUser);
  const [browserRefresh, setBrowserRefresh] = useState(false);
  const [sellingPlanInfo, setSellingPlanInfo] = useState(null);

  useEffect(() => {
    if (browserRefresh || !businessId) {
      navigation("/");
    }
  }, [browserRefresh, setBrowserRefresh]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      setBrowserRefresh(true);
    };

    const handlePopState = () => {
      navigation("/");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigation]);

  useEffect(() => {
    // console.log("Cart-------------", stripe);
    if (!stripe) {
      return;
    } else if (
      (itemType === "service" && !isEmptyObject(selectedServiceCheckout)) ||
      (itemType === "product" &&
        selectedCheckoutCart &&
        !isEmptyObject(selectedCheckoutCart)) ||
      (itemType === "autoship" && !isEmptyObject(selectedCheckoutAutoShipCart))
    ) {
      // Retrieve the "payment_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = searchParams.get("payment_intent_client_secret");
      // Retrieve the PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case "succeeded":
            const cartTotal = paymentIntent.amount;
            if (itemType === "product" || itemType === "autoship") {
              updateCart(cartTotal);
            } else {
              updateServiceCart(cartTotal);
            }

            setMessage("Success! Payment received.");
            break;

          case "processing":
            setMessage(
              "Payment processing. We'll update you when payment is received."
            );
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage("Payment failed. Please try another payment method.");
            break;

          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    } else {
      setIsLoading(false);
      navigation("/");
    }
  }, [stripe]);

  const [updateConsumerTransactionDetails] = useMutation(
    UPDATE_CONSUMER_TRANSACTION_DETAILS
  );
  const [updateItemQuantity] = useMutation(UPDATE_ITEM_QUANTITY);
  const [removeCartCompany] = useMutation(REMOVE_CART_COMPANY);
  const updateCart = async (cartTotal) => {
    let transactionCart = JSON.parse(
      JSON.stringify(
        itemType === "autoship"
          ? selectedCheckoutAutoShipCart
          : selectedCheckoutCart
      )
    ); // Deep copy
    delete transactionCart.__typename;

    const encryptedCoupon = localStorage.getItem("promotionCode");
    if (encryptedCoupon) {
      // Decrypt the encrypted data
      const bytes = CryptoJS.AES.decrypt(
        encryptedCoupon,
        process.env.REACT_APP_ENCRYPTION_KEY
      );
      let coupon = bytes.toString(CryptoJS.enc.Utf8);
      coupon = JSON.parse(coupon);
      // transactionCart.coupon = coupon;
      transactionCart = {
        ...transactionCart,
        coupon: coupon,
      };
      // console.log("Final object----------------------", transactionCart,coupon);
      localStorage.removeItem("promotionCode");
    }
    const itemArray = [];
    let quantityCount = 0;
    for (let i = 0; i < transactionCart.items.length; i++) {
      let item = transactionCart.items[i];
      quantityCount = +item.quantity;
      transactionCart.items[i] = {
        _id: item._id,
        title: item.title,
        price: item.price,
        discountedPrice: item.finalTotal,
        uri:
          item.medias[0].mediaType === "photo"
            ? item.medias[0].uri
            : item.medias[0].uri_thumbnail,
        description: item.description,
        productDetails: item.productDetails,
        quantity: item.quantity,
        variantCombination: item?.variantCombination
          ? item.variantCombination
          : null,
      };
      if (itemType === "autoship") {
        let sellingPlan = {
          ...transactionCart.sellingPlan,
          billingCycle: parseInt(item.billingCycle),
        };
        transactionCart.items[i] = {
          ...transactionCart.items[i],
          sellingPlan: sellingPlan,
        };
        delete transactionCart.sellingPlan;
      }
      itemArray.push({
        itemId: item._id,
        quantity: item.quantity,
        type: itemType === "autoship" ? "Product" : transactionCart.type,
        variantCombination: item?.variantCombination
          ? item.variantCombination
          : null,
      });
    }

    // console.log("Final object----------------------", transactionCart);

    const transactionResult = await updateConsumerTransactionDetails({
      variables: {
        cart: transactionCart,
        price: parseFloat(cartTotal / 100).toFixed(2),
        discount: currentUser.subscription.subscriptionDiscount,
        type: transactionCart.type,
      },
    });
    if (transactionResult.data?.updateConsumerTransactionDetails?.success) {
      let transactionId =
        transactionResult.data?.updateConsumerTransactionDetails?.transactionId;
      delete transactionCart.shippingCost;
      delete transactionCart.coupon;
      await updateItemQuantity({
        variables: {
          itemQuantity: itemArray,
        },
      });

      if (itemType === "product") {
        const response = await removeCartCompany({
          variables: {
            cart: transactionCart,
          },
        });
        if (response.data && response.data.removeCartCompany.success) {
          // setIsLoading(false);
          fetchTransactionData(transactionId, quantityCount);
        } else {
          setIsLoading(false);
        }
      } else {
        fetchTransactionData(transactionId, quantityCount);
      }
    } else {
      // Note: handle this when there is some problem. eg no internet.
      setIsLoading(false);
    }
  };

  const updateServiceCart = async (cartTotal) => {
    let transactionCart = JSON.parse(JSON.stringify(selectedServiceCheckout));
    delete transactionCart.duration.__typename;
    // console.log("Transaction log----------------", transactionCart);
    let cartInput = {};
    cartInput.companyName = transactionCart.providerName;
    cartInput.businessId = transactionCart.businessId;
    cartInput.type = transactionCart.itemType;
    cartInput.bookingDate = transactionCart.bookingData.bookingDate;
    cartInput.bookingClients = transactionCart.bookingData.bookingClients;
    cartInput.startTime = transactionCart.bookingData.serviceSatrtTime;
    cartInput.endTime = transactionCart.bookingData.serviceEndTime;
    cartInput.bookedSpots = transactionCart.bookingData.noOfSpots;
    cartInput.serviceType = transactionCart.bookingData.serviceType;
    cartInput.serviceOption = transactionCart.serviceOption;
    cartInput.taxRate = transactionCart.taxRate;
    cartInput.items = [
      {
        _id: transactionCart._id,
        title: transactionCart.title,
        price: transactionCart.price,
        discountedPrice: transactionCart.finalTotal,
        uri:
          transactionCart.medias[0].mediaType === "photo"
            ? transactionCart.medias[0].uri
            : transactionCart.medias[0].uri_thumbnail,
        description: transactionCart.description,
        totalReview: transactionCart.totalReview,
        totalRating: transactionCart.totalRating,
        duration: transactionCart.duration,
        serviceLocation: transactionCart.serviceLocation,
      },
    ];
    const transactionResult = await updateConsumerTransactionDetails({
      variables: {
        cart: cartInput,
        price: parseFloat(cartTotal / 100).toFixed(2),
        discount: currentUser.subscription.subscriptionDiscount,
        type: "Service",
      },
    });
    if (transactionResult.data?.updateConsumerTransactionDetails?.success) {
      let transactionId =
        transactionResult.data?.updateConsumerTransactionDetails?.transactionId;
      fetchTransactionData(transactionId);
    } else {
      setIsLoading(false);
    }
  };

  const [fetchHistoryDetail, { loading: loadingHistoryDetail }] = useLazyQuery(
    FETCH_HISTORY_DATA,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchHistoryDetail) {
          let historyOrder = data.fetchHistoryDetail;
          let historyOrderSummary = historyOrder.orderSummary;
          // console.log("Final value--------------", historyOrder);
          if (itemType === "autoship" || itemType === "product") {
            setProducts(historyOrderSummary.itemInfo);
            if (itemType === "autoship") {
              setSellingPlanInfo(historyOrderSummary.sellingPlan);
              setProductPricing({
                subTotal: historyOrderSummary.subtotal,
                autoShipDiscount:
                  historyOrderSummary?.sellingPlan?.sellingPlanDiscount,
                discountTotal: historyOrderSummary.totalDiscount,
                taxableTotal: historyOrderSummary.taxAmount,
                taxAmount: historyOrderSummary.taxAmount,
                finalCheckoutTotal: historyOrderSummary.total,
              });
              setOtherPricingInfo({
                shippingCost: historyOrderSummary?.shippingCost,
                taxRate: historyOrderSummary.taxRate,
                sellingPlan: {
                  discount: historyOrderSummary.sellingPlan.discount,
                },
              });
            } else {
              setOtherPricingInfo({
                shippingCost: historyOrderSummary?.shippingCost,
                taxRate: historyOrderSummary.taxRate,
              });
              const coupon = historyOrder.orderSummary?.coupon
                ? historyOrder.orderSummary.coupon
                : {};
              setProductPricing({
                coupon,
                subTotal: historyOrderSummary.subtotal,
                couponCode: !isEmptyObject(coupon)
                  ? coupon.discountedAmount
                  : 0,
                discountTotal: historyOrderSummary.totalDiscount,
                taxableTotal: historyOrderSummary.taxAmount,
                taxAmount: historyOrderSummary.taxAmount,
                finalCheckoutTotal: historyOrderSummary.total,
              });
            }
          } else {
            const purchasedService = historyOrderSummary.itemInfo.map(
              (service) => {
                const bookingData = {
                  noOfSpots: historyOrderSummary.bookedSpots,
                };
                return {
                  ...service,
                  bookingData,
                };
              }
            );
            setProducts(purchasedService);
            setOrderInfo(historyOrder.orderInfo);
            setOtherPricingInfo({
              taxRate: historyOrderSummary.taxRate,
            });
          }

          setProductAddress(historyOrder.deliveryAddress);
          const dateObj = new Date(historyOrder.createdAt);
          // Format the date to "MM/DD/YYYY" format
          const formattedDate = `${(dateObj.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${dateObj
            .getDate()
            .toString()
            .padStart(2, "0")}/${dateObj.getFullYear()}`;
          setOrderHistroy({ ...historyOrder, createdAt: formattedDate });
          // console.log("Histroy-------------------", historyOrder);
        }
      },
    }
  );

  const fetchTransactionData = async (transactionId, quantityCount) => {
    await fetchHistoryDetail({
      variables: {
        transactionId: transactionId,
      },
    });
    if (quantityCount && itemType === "product") {
      dispatch(decreaseUserCartCount(quantityCount));
      dispatch(setUserCart([]));
    }
    if (itemType === "service") {
      dispatch(resetUserServiceCart());
    }
    setIsLoading(false);
  };

  return isLoading || loadingHistoryDetail ? (
    <LoadingSpinner overlay />
  ) : (
    <>
      <Container style={{ width: "99%" }}>
        <Row className="justify-content-center mt-4">
          <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            //   className="justify-content-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{ flexDirection: "row", display: "flex", width: "100%" }}
            >
              <CustomLabel
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginRight: "2%",
                }}
              >
                {itemType === "service"
                  ? "Thank you for booking !"
                  : "Thank you for your order !"}
              </CustomLabel>
              <IoCheckmark color={neturalGreen} size={30} />
            </div>
            <div style={{ marginTop: "1%" }}>
              <CustomLabel
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginRight: "2%",
                  color: netural400,
                }}
              >
                Your order has been successfully placed. Check your email{" "}
                <CustomLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: neturalBlack,
                  }}
                >
                  {currentUser.email}
                </CustomLabel>{" "}
                for receipt and payment details.
              </CustomLabel>
            </div>
            <div
              style={{ borderTop: "1px solid #dadada", marginTop: "5%" }}
            ></div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                width: "100%",
                marginTop: "3%",
              }}
            >
              <CustomLabel
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginRight: "2%",
                }}
              >
                {itemType === "service" ? "Booking details" : "Order details"}
              </CustomLabel>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                  marginTop: "4%",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginRight: "2%",
                  }}
                >
                  {itemType === "product" || itemType === "autoship"
                    ? "Order"
                    : "Booking"}{" "}
                  ID
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    marginRight: "2%",
                    color: netural400,
                  }}
                >
                  #{orderHistory._id}
                </CustomLabel>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                  marginTop: "4%",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginRight: "2%",
                  }}
                >
                  {itemType === "product" || itemType === "autoship"
                    ? "Order"
                    : "Purchase"}{" "}
                  date
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    marginRight: "2%",
                    color: netural400,
                  }}
                >
                  {orderHistory.createdAt}
                </CustomLabel>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                  marginTop: "4%",
                }}
              >
                <CustomLabel
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginRight: "2%",
                  }}
                >
                  Company
                </CustomLabel>
                <CustomLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    marginRight: "2%",
                    color: netural400,
                  }}
                >
                  {orderHistory.companyName}
                </CustomLabel>
              </div>
              {itemType === "service" && (
                <>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "100%",
                      marginTop: "4%",
                    }}
                  >
                    <CustomLabel
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "2%",
                      }}
                    >
                      Scheduled date
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginRight: "2%",
                        color: netural400,
                      }}
                    >
                      {orderInfo.scheduleDate}
                    </CustomLabel>
                  </div>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "100%",
                      marginTop: "4%",
                    }}
                  >
                    <CustomLabel
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "2%",
                      }}
                    >
                      Service method
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginRight: "2%",
                        color: netural400,
                      }}
                    >
                      {orderInfo.serviceDuration}
                      {" - "}
                      {orderInfo.serviceMethod}
                    </CustomLabel>
                  </div>
                </>
              )}
            </div>
            <div
              style={{ borderTop: "1px solid #dadada", marginTop: "5%" }}
            ></div>
            {itemType === "product" || itemType === "autoship" ? (
              <>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                    marginTop: "3%",
                  }}
                >
                  <CustomLabel
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginRight: "2%",
                    }}
                  >
                    Shipment
                  </CustomLabel>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "100%",
                      marginTop: "4%",
                    }}
                  >
                    <CustomLabel
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginRight: "2%",
                      }}
                    >
                      Shipping address
                    </CustomLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel
                        style={{ color: netural400, fontSize: "16px" }}
                      >
                        {`${orderHistory.fullName}`}
                      </CustomLabel>
                    </div>
                    <CustomLabel
                      style={{ color: netural400, fontSize: "16px" }}
                    >
                      {currentUser.email}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {`${productAddress.streetName} ${
                        productAddress.cityName
                      } ${productAddress.stateAndZipCode}${","} ${
                        productAddress.country
                      }`}
                    </CustomLabel>
                  </div>
                </div>
                <div
                  style={{ borderTop: "1px solid #dadada", marginTop: "5%" }}
                ></div>
                {itemType === "autoship" && (
                  <>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          marginRight: "2%",
                        }}
                      >
                        Delivery frequency
                      </CustomLabel>
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          width: "100%",
                          marginTop: "4%",
                        }}
                      >
                        <CustomLabel
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginRight: "2%",
                          }}
                        >
                          Delivery every
                        </CustomLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "3%",
                          }}
                        >
                          <CustomLabel
                            style={{ color: netural400, fontSize: "16px" }}
                          >
                            {`${sellingPlanInfo.billingCycle} ${sellingPlanInfo.frequencyType}`}
                          </CustomLabel>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid #dadada",
                        marginTop: "5%",
                      }}
                    ></div>
                  </>
                )}
                <div style={{ marginTop: "3%" }}>
                  <CustomButton
                    title={"Continue shopping"}
                    customStyle={{ width: "100%" }}
                    handleButtonClick={() => {
                      navigation(`/products/category/Pets and Animals`);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                    marginTop: "3%",
                  }}
                >
                  <CustomLabel
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginRight: "2%",
                    }}
                  >
                    Service location
                  </CustomLabel>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "100%",
                      marginTop: "4%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel
                        style={{ color: netural400, fontSize: "16px" }}
                      >
                        {`${orderHistory.fullName}`}
                      </CustomLabel>
                    </div>
                    <CustomLabel
                      style={{ color: netural400, fontSize: "16px" }}
                    >
                      {currentUser.email}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {`${productAddress.streetName} ${
                        productAddress.cityName
                      } ${productAddress.stateAndZipCode}${","} ${
                        productAddress.country
                      }`}
                    </CustomLabel>
                  </div>
                </div>
                <div
                  style={{ borderTop: "1px solid #dadada", marginTop: "5%" }}
                ></div>
              </>
            )}
          </Col>
          <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            xs={12}
            //   className="justify-content-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <OrderSummary
              products={products}
              itemType={itemType}
              pricing={productPricing}
              selectedCheckoutCart={otherPricingInfo}
              promotionalCode={productPricing?.coupon || null}
            />
          </Col>
          <Col
            xs={1}
            sm={1}
            md={1}
            style={{ backgroundColor: "transparent" }}
          ></Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PaymentStatus;
