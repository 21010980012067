import React from "react";
import { returnPaginationRange } from "../../../utils/constants";
import "../pagination/pagination.styles.css";
import { Link } from "react-router-dom";

const Pagination = ({
  pageNumber,
  totalPages,
  siblings,
  itemsPerPage,
  leftAndRightSkip,
  onPageChange,
}) => {
  const handleClick = (page, index) => {
    const rightDotPlace = 3 + 2 * siblings;
    if (index === rightDotPlace && page === "...") {
      onPageChange(pageNumber + leftAndRightSkip);
    } else if (index === 1 && page === "...") {
      onPageChange(pageNumber - leftAndRightSkip);
    } else {
      onPageChange(page);
    }
  };

  const paginatedArrayRange = returnPaginationRange(
    totalPages,
    pageNumber,
    siblings,
    itemsPerPage
  );

  return (
    <nav>
      <ul className="pagination justify-content-center">
        {/* <li className={`page-item ${pageNumber === 1 && 'disabled'}`}>
            <span className="page-link" onClick={() => handleClick(pageNumber - 1)}>&laquo;</span>
            </li> */}
        <li className={`page-item action ${pageNumber === 1 && "disabled"}`}>
          <Link style={{ textDecoration: "none" }} >
            <span
              className="page-link"
              onClick={() => handleClick(pageNumber - 1)}
            >
              &lsaquo;
            </span>
          </Link>
        </li>
        {paginatedArrayRange.map((value, index) => {
          return (
            <li
              key={index}
              className={`page-item ${pageNumber === value && "active"}`}
            >
              <Link style={{ textDecoration: "none" }}>
                <span
                  className="page-link"
                  onClick={() => handleClick(value, index)}
                >
                  {value}
                </span>
              </Link>
            </li>
          );
        })}
        <li
          className={`page-item action ${
            pageNumber === totalPages && "disabled"
          }`}
        >
          <Link style={{ textDecoration: "none" }}>
            <span
              className="page-link"
              onClick={() => handleClick(pageNumber + 1)}
            >
              &rsaquo;
            </span>
          </Link>
        </li>
        {/* <li className={`page-item ${pageNumber === totalPages && 'disabled'}`}>
            <span className="page-link" onClick={() => handleClick(pageNumber + 1)}>&raquo;</span>
            </li> */}
      </ul>
    </nav>
  );
};

export default Pagination;
