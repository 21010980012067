import { Col, Container, Image, Row } from "react-bootstrap";
import Footer from "../home-page-footer/home-page-footer.component";
import SectionBody from "../section-body/section-body.component";
import SectionTitle from "../section-title/section-title.component";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import { useEffect } from "react";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../utils/constants";

const PartnerWithUs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={() => {}}
                  type={"Partner with us"}
                />
                <PageTitle title={"Partner with us"} />
              </div>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="Why partner with us?" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/images/partner-1.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      Are you a business offering amazing pet products?
                      <SectionBody>
                        Join our platform and expand your reach by advertising
                        or selling directly on our app and website.
                      </SectionBody>
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Partnering with us provides you with a unique opportunity
                      to connect with thousands of pet lovers and potential
                      customers through our interactive and user-friendly
                      channels.
                    </SectionBody>
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="Why choose our platform?" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      <ul>
                        <li>
                          Increase Your Visibility: Showcase your products to a
                          larger exclusive audience through our popular app and
                          website, specifically designed for pet enthusiasts.
                        </li>
                      </ul>
                    </SectionBody>
                    <SectionBody style={{ marginTop: "2%" }}>
                      <ul>
                        <li>
                          Multiple Sales Channels: Our platform allows you to
                          market and sell your products seamlessly across both
                          our app and website, giving you maximum exposure and
                          flexibility.
                        </li>
                      </ul>
                    </SectionBody>
                    <SectionBody style={{ marginTop: "2%" }}>
                      <ul>
                        <li>
                          Dedicated Support: Our team is here to assist you with
                          setting up, promoting, and optimizing your products,
                          ensuring you achieve the best results possible.
                        </li>
                      </ul>
                    </SectionBody>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/images/partner-2.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="Membership tiers" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/images/partner-3.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      We offer a range of membership options designed to fit the
                      unique needs of your business.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Choose from various tiers, each with its own set of
                      benefits.
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      Explore and select the membership that best aligns with
                      your goals to start showcasing and selling your products.
                      Join us today and find the right plan that empowers your
                      business to grow and thrive!
                    </SectionBody>
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "4%" }}
              >
                <SectionTitle title="How to partner with us" />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "32px",
                }}
              >
                <Row>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="text-column"
                  >
                    <SectionBody>
                      Don't miss the chance to grow your business and connect
                      with more customers.
                    </SectionBody>
                    <SectionBody>
                      Partner with us today by downloading our app and creating
                      a business account. 
                    </SectionBody>
                    <SectionBody style={{ marginTop: "5%" }}>
                      If you need help setting up a business account or
                      assistance with other inquiries, get in touch with our
                      team at{" "}
                      <SectionBody style={{ textDecoration: "underline" }}>
                        my@greggii.com
                      </SectionBody>{" "}
                      or give us a call at 1-786-877-6205. Let’s create a
                      thriving pet community together!
                    </SectionBody>
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <a
                        href={GOOGLE_STORE_URL}
                        style={{ marginRight: "3%" }}
                        target="_blank"
                      >
                        <img src="./images/Badge.png" alt="" />
                      </a>
                      <a href={APPLE_STORE_URL} target="_blank">
                        <img src="./images/Frame 11.png" alt="" />
                      </a>
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="image-column"
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/images/partner-4.png"}
                      alt="Logo"
                      // className="logo-image"
                      style={{ objectFit: "contain", width: "90%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PartnerWithUs;
