import { netural400 } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const SectionBody = ({ style: userStyle, children, ...restProps }) => {
  const defaultStyle = {
    fontSize: "16px",
    color: netural400,
  };
  const combinedStyle = { ...defaultStyle, ...userStyle };
  return (
    <CustomLabel style={combinedStyle} {...restProps}>
      {children}
    </CustomLabel>
  );
};

export default SectionBody;
