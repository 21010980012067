import { Col, Row } from "react-bootstrap";
import { netural400, netural50 } from "../../utils/colors";
import { MdArrowRightAlt } from "react-icons/md";
import CustomLabel from "../shared/label/custom-label.component";
import { useNavigate } from "react-router-dom";
import "./home-page-browse-products-and-services.style.css";

const HomePageBrowseProductsServices = () => {
  const navigation = useNavigate();
  return (
    <div style={{ margin: "3%" }}>
      <Row>
        <Col
          lg={12}
          md={12}
          sm={12}
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <CustomLabel
            className="section-1-title"
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Products and services at your disposal
          </CustomLabel>
        </Col>
        <div>
          <Row>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "5%",
              }}
            >
              <div
                style={{
                  backgroundColor: netural50,
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="mobile-width"
              >
                <div style={{ marginTop: "1%", flexDirection: "column" }}>
                  <CustomLabel
                    style={{ fontSize: "1.37em", fontWeight: "bold" }}
                  >
                    We’ve got it all!
                  </CustomLabel>
                  <CustomLabel
                    style={{
                      fontSize: "0.87em",
                      fontWeight: "500",
                      color: netural400,
                      marginTop: "1%",
                    }}
                  >
                    From accessories, fashion to home essentials and beyond,
                    shop our wide catalog of products to find what you need.
                  </CustomLabel>
                  <Row style={{ marginTop: "2%" }}>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                      <CustomLabel
                        className="shop-button-style"
                        onClick={() =>
                          navigation("/products/category/Pets and Animals")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Shop products
                        <MdArrowRightAlt
                          size={20}
                          style={{ marginLeft: "5%" }}
                          color="#1b1c1e"
                        />
                      </CustomLabel>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="./images/Image.png"
                          alt="Logo"
                          style={{
                            width: "50%",
                            borderRadius: "16px",
                            transform: "rotate(-348.02deg)",
                            marginRight: "3%",
                            marginBottom: "3%",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ display: "flex", marginTop: "5%" }}
            >
              <div
                style={{
                  backgroundColor: netural50,
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="mobile-width"
              >
                <div
                  style={{
                    marginTop: "1%",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <CustomLabel
                    style={{ fontSize: "1.37em", fontWeight: "bold" }}
                  >
                    Find what you need!
                  </CustomLabel>
                  <CustomLabel
                    style={{
                      fontSize: "0.87em",
                      fontWeight: "500",
                      color: netural400,
                      marginTop: "1%",
                    }}
                  >
                    Uncover a world of possibilities with selected offerings
                    from our trusted partner businesses.
                  </CustomLabel>
                  <Row style={{ marginTop: "2%" }}>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <CustomLabel
                        className="shop-button-style"
                        onClick={() => navigation("/services")}
                        style={{ cursor: "pointer", }}
                      >
                        Shop services
                        <MdArrowRightAlt
                          size={20}
                          style={{ marginLeft: "5%" }}
                          color="#1b1c1e"
                        />
                      </CustomLabel>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="./images/Image (1).png"
                          alt="Logo"
                          style={{
                            width: "50%",
                            borderRadius: "16px",
                            transform: "rotate(-348.02deg)",
                            marginRight: "3%",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default HomePageBrowseProductsServices;
