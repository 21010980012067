import { Col, Row } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import "./home-page-footer.style.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div style={{ marginTop: "3%", borderTop: "1px solid #dadada" }}>
      <Row style={{ width: "100%", marginTop: "2%" }}>
        <Col xl={3} lg={3} md={3} sm={5} xs={5} className="footer-style">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel style={{ fontWeight: "600", color: neturalBlack }}>
              Company
            </CustomLabel>
            <Link
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              to={`aboutus`}
            >
              About
            </Link>
            <a
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              href="https://my.greggii.com/policy.pdf"
              target={"_blank"}
            >
              Terms
            </a>
            <a
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              href="https://my.greggii.com/policy.pdf"
              target={"_blank"}
            >
              Privacy
            </a>
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={5} xs={5} className="footer-style">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel style={{ fontWeight: "600", color: neturalBlack }}>
              Social
            </CustomLabel>
            <a
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              href="https://facebook.com/My-By-Greggii-108853304959787/"
              target={"_blank"}
            >
              Facebook
            </a>
            <a
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              href="https://twitter.com/my_greggii"
              target={"_blank"}
            >
              Twitter/X
            </a>
            <a
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
                textDecoration: "none",
              }}
              href="https://www.instagram.com/my_greggii/"
              target="_blank"
            >
              Instagram
            </a>
            <CustomLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
              }}
            >
              Email
            </CustomLabel>
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={5} xs={5} className="footer-style">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel style={{ fontWeight: "600", color: neturalBlack }}>
              Partner with us
            </CustomLabel>
            <CustomLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
              }}
            >
              Advertise on “My..”
            </CustomLabel>
            <CustomLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
              }}
            >
              Sell on “My..”
            </CustomLabel>
          </div>
        </Col>
        <Col xl={2} lg={2} md={2} sm={5} xs={5} className="footer-style">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel style={{ fontWeight: "600", color: neturalBlack }}>
              Account
            </CustomLabel>
            <CustomLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
              }}
            >
              Account details
            </CustomLabel>
            <CustomLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "3%",
                color: netural400,
              }}
            >
              Membership
            </CustomLabel>
          </div>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: neturalBlack,
            paddingTop: "0.5%",
            paddingBottom: "0.5%",
          }}
        >
          <Col
            lg={3}
            md={3}
            sm={6}
            xs={6}
            style={{
              display: "flex",
              marginLeft: "2%",
            }}
          >
            <CustomLabel
              style={{ fontSize: "14px", fontWeight: "500", color: "white" }}
            >
              © My... 2024
            </CustomLabel>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
