import { Col, Row } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import "./order-summary-product-list.style.css";

const OrderSummaryProductList = ({ product, index, itemType }) => {
  const {
    medias,
    title,
    finalTotal,
    price,
    variantCombination,
    quantity,
    uri,
    bookingData
  } = product;
  return (
    <div key={index} style={{ marginTop: "5%" }}>
      <Row
        style={{
          width: "100%",
          marginTop: "1%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Col xs={4} sm={4} md={4} lg={4} xl={2}>
          <img
            src={`${process.env.REACT_APP_HOST_URL}${
              medias ? medias[0].uri : uri
            }`}
            alt={title}
            className="order-product-list-image"
          />
        </Col>
        <Col
          xs={8}
          sm={8}
          md={8}
          lg={8}
          xl={10}
          style={{ paddingLeft: "2%", paddingRight: "0px" }}
        >
          <div
            style={{
              // marginLeft: "2%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <CustomLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
                className="checkout-product-title"
              >
                {title}
              </CustomLabel>
              <CustomLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
              >
                {finalTotal ? `$ ${finalTotal}` : `$ ${price}`}
              </CustomLabel>
            </div>
            {itemType === "product" || itemType === "autoship" ? (
              <>
                {variantCombination ?
                <CustomLabel
                  style={{
                    fontSize: "16px",
                    color: netural400,
                    paddingTop: "1%",
                  }}
                >
                  {variantCombination}
                </CustomLabel>
                :null}
                <div
                  style={{
                    display: "flex",
                    marginTop: "1%",
                    alignItems: "center",
                  }}
                >
                  <CustomLabel
                    style={{
                      fontSize: "16px",
                      color: netural400,
                    }}
                  >{`Qty: ${quantity}`}</CustomLabel>
                </div>
              </>
            ) : null}
            {itemType === "service" && (
              <CustomLabel
                style={{
                  fontSize: "16px",
                  color: netural400,
                  paddingTop: "1%",
                }}
              >
                {bookingData.noOfSpots}{bookingData.noOfSpots > 1 ? ` Spots` :` Spot`}
              </CustomLabel>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderSummaryProductList;