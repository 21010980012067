import { Modal } from "react-bootstrap";
import {
  error50,
  error900,
  netural400,
  netural50,
  neturalBlack,
  primaryOrange,
  success50,
  success900,
} from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { IoArrowForward } from "react-icons/io5";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ALL_ORDER_SCHEDULE_DETAILS } from "../../graphQL/subscription/subscription";
import { useEffect, useState } from "react";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { Link } from "react-router-dom";

const SubscriptionOrderDetailsModal = (props) => {
  const { subscriptionid } = props;
  const [scheduleDetails, setScheduleDetails] = useState([]);

  useEffect(() => {
    if (subscriptionid) {
      fetchAllOrderScheduleDetails({
        variables: {
          subscriptionId: subscriptionid,
        },
      });
    }
  }, []);

  const [
    fetchAllOrderScheduleDetails,
    { loading: loadingOrderScheduleDetails },
  ] = useLazyQuery(FETCH_ALL_ORDER_SCHEDULE_DETAILS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchAllOrderScheduleDetails) {
        const orderScheduleDetails = data.fetchAllOrderScheduleDetails;
        setScheduleDetails(orderScheduleDetails);
      }
    },
  });
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        className="login-modal-style"
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
            marginBottom: "2%",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: neturalBlack,
              }}
            >
              Order details
            </CustomLabel>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginBottom: "2%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "3%",
            borderTop: "solid 1px #dadada",
          }}
        >
          {loadingOrderScheduleDetails ? (
            <LoadingSpinner />
          ) : (
            scheduleDetails.map((orderInfo, index) => {
              return (
                <div key={index}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Order ID#{" "}
                      {orderInfo.status === "successful"
                        ? orderInfo.transactionId
                        : orderInfo.id}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "14px",
                        marginTop: "1%",
                      }}
                    >
                      {orderInfo.orderDate}
                    </CustomLabel>
                    <div>
                      <CustomLabel
                        style={{
                          color:
                            orderInfo.status === "successful"
                              ? success900
                              : orderInfo.status === "cancelled" ||
                                orderInfo.status === "unsucccessful"
                              ? error900
                              : neturalBlack,
                          fontSize: "14px",
                          backgroundColor:
                            orderInfo.status === "successful"
                              ? success50
                              : orderInfo.status === "cancelled" ||
                                orderInfo.status === "unsucccessful"
                              ? error50
                              : netural50,
                          borderRadius: "12px",
                          paddingLeft: "2%",
                          paddingRight: "2%",
                          marginTop: "1%",
                          textTransform: "capitalize",
                        }}
                      >
                        {orderInfo.status}
                      </CustomLabel>
                    </div>
                    {orderInfo.receiptURL && (
                      <div>
                        <Link target="_blank" to={orderInfo.receiptURL}>
                          <CustomLabel
                            style={{
                              fontWeight: "600",
                              color: primaryOrange,
                              fontSize: "14px",
                              marginTop: "2%",
                              cursor: "pointer",
                            }}
                          >
                            View invoice
                            <IoArrowForward color={primaryOrange} size={20} />
                          </CustomLabel>
                        </Link>
                      </div>
                    )}
                  </div>
                  {scheduleDetails.length - 1 !== index && (
                    <div
                      style={{
                        border: "solid 1px #dadada",
                        marginTop: "2%",
                        marginBottom: "2%",
                      }}
                    ></div>
                  )}
                </div>
              );
            })
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubscriptionOrderDetailsModal;
