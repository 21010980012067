import {
  error50,
  error900,
  netural400,
  neturalBlack,
  secondary50,
  secondary500,
  success50,
  success900,
} from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const SubscriptionDetialsSection = ({ subscriptionDetails, cancelInfo }) => {
  const {
    status,
    subscriptionId,
    createdOn,
    completedOrders,
    delivery,
    minimumCycles,
    maximumCycles,
  } = subscriptionDetails;
  // const { cancelledAt, reason, name } = cancelInfo;
  return (
    <div style={{ marginTop: "2%" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CustomLabel style={{ fontWeight: "bold", fontSize: "18px" }}>
          Subscription details
        </CustomLabel>
      </div>
      <div style={{ marginTop: "3%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Status
          </CustomLabel>
          <CustomLabel
            style={{
              color:
                status === "active"
                  ? success900
                  : status === "cancelled"
                  ? error900
                  : secondary500,
              fontSize: "14px",
              backgroundColor:
                status === "active"
                  ? success50
                  : status === "cancelled"
                  ? error50
                  : secondary50,
              borderRadius: "12px",
              paddingLeft: "2%",
              paddingRight: "2%",
              textTransform: "capitalize",
            }}
          >
            {status}
          </CustomLabel>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Subscription ID
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            #{subscriptionId}
          </CustomLabel>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Created on
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {createdOn}
          </CustomLabel>
        </div>
        {cancelInfo ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <CustomLabel
                style={{
                  fontWeight: "600",
                  color: neturalBlack,
                  fontSize: "14px",
                }}
              >
                Cancelled on
              </CustomLabel>
              <CustomLabel
                style={{
                  color: netural400,
                  fontSize: "14px",
                }}
              >
                {cancelInfo.cancelledAt ? cancelInfo.cancelledAt : null}
              </CustomLabel>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <CustomLabel
                style={{
                  fontWeight: "600",
                  color: neturalBlack,
                  fontSize: "14px",
                }}
              >
                Cancelled by
              </CustomLabel>
              <CustomLabel
                style={{
                  color: netural400,
                  fontSize: "14px",
                }}
              >
                {cancelInfo.name ? cancelInfo.name : null}
              </CustomLabel>
            </div>
          </>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2%",
            justifyContent: "space-between",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Completed orders
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {completedOrders}
          </CustomLabel>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2%",
            justifyContent: "space-between",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Delivery every
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {delivery}
          </CustomLabel>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2%",
            justifyContent: "space-between",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Minimum recurring orders
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {minimumCycles}
          </CustomLabel>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2%",
            justifyContent: "space-between",
          }}
        >
          <CustomLabel
            style={{
              fontWeight: "600",
              color: neturalBlack,
              fontSize: "14px",
            }}
          >
            Maximum recurring orders
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {maximumCycles}
          </CustomLabel>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetialsSection;
