export const selectCartCount = (state) => state.cart.cartCount;
export const selectCart = (state) => state.cart.cart;
export const selectCartItems = (state) => {
  const items = state.cart.cart;
  return items.map((item) => item.item);
};
//by business id
export const selectCartProducts = (businessId) => (state) => {
  const items = state.cart.cart;
  return items.find((item) => item.businessId === businessId);
};
export const selectCartCheckoutByBusinessId = (businessId) => (state) => {
  if (businessId) {
    const items = state.cart?.cart ? state.cart?.cart : [];
    if (state.cart) {
        if(items.length > 0){
            const cart = items.find((item) => item.businessId === businessId);
            return cart;
        }else{
            return {};
        }
    } else {
      return {};
    }
    // const products = cart.items.map(item => item)
  } else {
    return {};
  }
};

export const selectServiceCartCheckoutByBusinessId =
  (businessId) => (state) => {
    if (businessId) {
      const service = state.cart.service;
      if (service && service.businessId === businessId) {
        return service;
      } else {
        return {};
      }
    }
  };

export const selectAutoshipCartCheckoutByBusinessId =
  (businessId) => (state) => {
    if (businessId) {
      const autoship = state.cart.autoship;
      if (autoship && autoship.businessId === businessId) {
        return autoship;
      } else {
        return {};
      }
    }
  };
