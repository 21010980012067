import { Route, Routes } from "react-router-dom";
import SubscriptionList from "../../components/subscription-list/subscription-list.component";
import SubscriptionDetials from "../../components/subscription-details/subscription-details.component";

const Subscriptions = () => {
  return (
    <Routes>
      <Route index element={<SubscriptionList /> } />
      <Route path="details/:subscriptionId" element={<SubscriptionDetials />} />
    </Routes>
  );
};

export default Subscriptions;
