import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { netural400 } from "../../utils/colors";
import ProductPrice from "../product-price/product-price.component";
import CustomLabel from "../shared/label/custom-label.component";

const ProductList = (props) => {
  const {
    productList: { medias, title, price, finalTotal, route, companyName, _id, providerName},
  } = props;
  const encryptedId = btoa(_id);
  return (
    <Col
      xl={3}
      lg={5}
      md={5}
      sm={5}
      xs={5}
      key={props.index}
      style={{ marginTop: "2%" }}
    >
      <Link
        to={props.type === "Products" ? `/products/details/${encryptedId}` : `/services/details/${encryptedId}`}
        style={{
          display: "flex",
          flexDirection: "column",
          textDecoration: "none",
        }}
      >
        <img
          alt=""
          src={`${process.env.REACT_APP_HOST_URL}${medias[0].uri}`}
          // src={"./images/Product-Image.png"}
          className="product-service-image-height"
        />
        <CustomLabel
          style={{
            fontWeight: "600",
            marginTop: "2%",
            display: "-webkit-box",
            WebkitLineClamp: 3, // Limit to 3 lines
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
        >
          {title}
        </CustomLabel>
        <CustomLabel
          style={{
            fontSize: "12px",
            color: netural400,
            cursor: "pointer",
          }}
        >
          {props.type === "Products" ? companyName:providerName}
        </CustomLabel>
        <CustomLabel
          style={{
            fontSize: "12px",
            color: netural400,
            marginTop: "1%",
            cursor: "pointer",
          }}
        >
          {route}
        </CustomLabel>
        <ProductPrice price={price} finalTotal={finalTotal} />
      </Link>
    </Col>
  );
};

export default ProductList;
