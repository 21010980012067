import { Routes, Route, Navigate } from "react-router-dom";
import * as React from "react";
import Navigation from "./navigation/navigation.component";
import Home from "./home/home-navigation.component";
import Products from "./product/product-navigation.component";
import Buy from "./buy/buy-navigation.component";
import AuthRoute from "./AuthRoute";
import Order from "./order/order.navigation";
import Services from "./services/services-navigation.component";
import MembershipNavigation from "./membership/membership-navigation.component";
import Subscriptions from "./subscriptions/subscriptions-navigation.component";
import AboutUsPage from "../components/about-us/about-us-page.component";
import PartnerWithUs from "../components/partner-with-us/partner-with-us.component";

function RoutePage() {
  return (
    <Routes>
      {/* <Route index element={<Home />} /> */}
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="services/*" element={<Services />} />
        <Route path="membership/*" element={<MembershipNavigation />} />
        <Route path="products/*" element={<Products />} />
        <Route path="buy/*" element={<AuthRoute element={Buy} />} />
        <Route path="order/*" element={<AuthRoute element={Order} />} />
        <Route
          path="subscriptions/*"
          element={<AuthRoute element={Subscriptions} />}
        />
        <Route path="aboutus" element={<AboutUsPage />} />
        <Route path="partnerwithus" element={<PartnerWithUs />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
      {/* <Route path="/" element={<PrivateRoute element={Navigation} />} >
        <Route index element={<Services/>} />
        <Route path="products/*" element={<Products/>}/>
        <Route path="services/*" element={<Services/>}/>
        <Route path="bookings/*" element={<BookingNavigation />}/>
        <Route path="sales/*" element={<Sales />}/>
      </Route> */}
      {/* FOR NOW, if user is entering wrong URL, redirecting them to our home page i.e services temporary */}
      {/* <Route path="*" element={<Navigate to="/services" />} />  */}
    </Routes>
  );
}

export default RoutePage;
