import { Fragment } from "react";
import { primaryOrange } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { ITEM_TYPE } from "../../utils/constants";

const AutoShipProductPrice = ({
  product,
  selectedVariant,
  priceStyle,
  finalTotalStyle,
  sellingPlan,
}) => {
  const calculateDiscountedPrice = (price, discount) => {
    return parseFloat((price * (1 - discount / 100)).toFixed(2));
  };

  const getPriceAndDiscount = (price, discount) => {
    const discountedPrice = calculateDiscountedPrice(price, discount);
    return { discountedPrice };
  };

  const productPrice = product.finalTotal;
  const variantPrice = selectedVariant?.finalTotal || productPrice;
  const discount = sellingPlan.discount;

  const { discountedPrice: productDiscountedPrice } = getPriceAndDiscount(
    productPrice,
    discount
  );

  const { discountedPrice: variantDiscountedPrice } = getPriceAndDiscount(
    variantPrice,
    discount
  );
  return (
    <Fragment>
      {((product.variants?.length === 0 &&
        product.itemType === ITEM_TYPE.PRODUCT) ||
        product.itemType === ITEM_TYPE.SERVICE) &&
      product.discount === "0" ? (
        <CustomLabel
          style={{
            fontWeight: "600",
            color: primaryOrange,
            marginRight: "2%",
            ...finalTotalStyle,
          }}
        >
          ${productDiscountedPrice}
        </CustomLabel>
      ) : (
        <div>
          <CustomLabel
            style={{
              fontWeight: "600",
              color: primaryOrange,
              marginRight: "2%",
              ...finalTotalStyle,
            }}
          >
            ${variantDiscountedPrice}
          </CustomLabel>
          {selectedVariant?.discount !== "0" && (
            <CustomLabel
              style={{ textDecoration: "line-through", ...priceStyle }}
            >
              ${selectedVariant?.finalTotal || product.finalTotal}
            </CustomLabel>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default AutoShipProductPrice;
