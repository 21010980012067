import { netural400 } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const ShippingAddressSection = ({ shippingAddress, name }) => {
  const { streetName, cityName, stateAndZipCode, country } = shippingAddress;
  return (
    <div style={{ marginTop: "3%" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CustomLabel style={{ fontWeight: "bold", fontSize: "18px" }}>
          Shipping address
        </CustomLabel>
      </div>
      <div style={{ marginTop: "3%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {name}
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {streetName}
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {cityName} {stateAndZipCode}
          </CustomLabel>
          <CustomLabel
            style={{
              color: netural400,
              fontSize: "14px",
            }}
          >
            {country}
          </CustomLabel>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddressSection;
