import { neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const SectionTitle = (props) => {
  const { title } = props;
  return (
    <CustomLabel
      style={{
        fontSize: "24px",
        fontWeight: "600",
        color: neturalBlack,
      }}
    >
      {title}
    </CustomLabel>
  );
};

export default SectionTitle;
