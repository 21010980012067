import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400, neturalBlack } from "../../utils/colors";
import { useState } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import CustomButton from "../button/button.component";

const CancelSubscriptionModal = (props) => {
  const [cancelReason, setCancelReason] = useState("");
  const [isValidCancelReason, setIsValidCancelReason] = useState(false);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: 0, marginLeft: "3%", marginRight: "3%" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <CustomLabel
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: neturalBlack,
            }}
          >
            Cancel subscription?
          </CustomLabel>
        </Modal.Title>
      </Modal.Header>
      <div
        style={{
          borderBottom: "1px solid #dadada",
          marginTop: "1%",
          marginBottom: "1%",
        }}
      ></div>
      <Modal.Body style={{ marginLeft: "3%", marginRight: "3%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CustomLabel
            style={{
              fontSize: "16px",
              color: netural400,
            }}
          >
            Are you sure you want to cancel this subscription?
          </CustomLabel>
          <CustomLabel
            style={{
              fontSize: "16px",
              color: netural400,
            }}
          >
            This cannot be undone.
          </CustomLabel>
          <div style={{ marginTop: "4%" }}>
            <Form.Group controlId="validationCustom03">
              <Form.Label style={{ fontSize: "16px", color: netural400 }}>
                Reason for deleting subscription*
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={5}
                value={cancelReason}
                onChange={(value) => {
                  if (value.target.value.trim().length !== 0) {
                    setCancelReason(value.target.value);
                  } else {
                    setCancelReason("");
                  }
                }}
                minLength={50}
                maxLength={3000}
              />
              {isValidCancelReason && (
                <CustomLabel
                  style={{
                    fontSize: "12px",
                    color: "red",
                    width: "100%",
                    marginTop: "2%",
                  }}
                >
                  <IoAlertCircleOutline
                    size={16}
                    color={"red"}
                    style={{ marginRight: "2%" }}
                  />
                  Please provide valid cancelation reason!
                </CustomLabel>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: 0, marginLeft: "3%", marginRight: "3%" }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Col xl={2} lg={2} md={6} sm={6} xs={6} style={{}}>
            <CustomButton
              title={"Cancel"}
              buttonType={"cancel"}
              handleButtonClick={props.onHide}
            />
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={6}
            xs={6}
            style={{ paddingRight: "0px" }}
          >
            <CustomButton
              title={"Cancel subscription"}
              buttonType={"delete"}
              handleButtonClick={() => {
                if (cancelReason === "") {
                  setIsValidCancelReason(true);
                } else {
                  props.onSubmit(cancelReason);
                }
              }}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelSubscriptionModal;
