import { Form } from "react-bootstrap";
import {
  netural400,
  netural50,
  neturalBlack,
  primaryGrey,
  primaryOrange,
} from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import AutoShipProductPrice from "../product-price/auto-ship-price.component";
import ProductDetailPrice from "../product-price/product-detail-price.component";
import IncrementDecrementButton from "../increment-decrement-button/increment-decrement-button.component";
import CustomButton from "../button/button.component";

const ProductDetailAutoShipDesign = (props) => {
  const {
    product,
    sellingPlan,
    selectDelivery,
    minimumBillingCycle,
    setMinimumBillingCycle,
    billingDropDown,
    quantityCount,
    setQuantityCount,
    selectedVariant,
    quantityError,
    setQuantityError,
    minValue,
    maxValue,
    isLoading,
    setIsLoading,
    isUserAuthenticated,
    checkValidation,
    setLoginTitle,
    setIsShowLogin,
  } = props;
  return (
    <div
      style={{
        marginTop: "4%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomLabel
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: neturalBlack,
        }}
      >
        {product?.variantCombinations.length > 0 ? "2." : "1."} Choose your
        delivery frequency
      </CustomLabel>
      <div style={{ marginTop: "3%" }}>
        {["One-time", "Subscribe"].map((label, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "3%",
                padding: "2%",
                borderTopLeftRadius: index === 0 ? "12px" : "0px",
                borderTopRightRadius: index === 0 ? "12px" : "0px",
                borderBottomLeftRadius: index === 1 ? "12px" : "0px",
                borderBottomRightRadius: index === 1 ? "12px" : "0px",
                borderColor:
                  label === selectDelivery ? primaryOrange : primaryGrey,
                borderWidth: "2px",
                borderStyle: "solid",
                borderBottomWidth: index === 0 ? "0px" : "2px",
                borderTopWidth: index === 1 ? "0px" : "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  props.handleChangeDeviveryOption(label);
                }}
              >
                <Form.Check
                  inline
                  name="group1"
                  type={"radio"}
                  id={index}
                  checked={label === selectDelivery}
                  onChange={() => {
                    props.handleChangeDeviveryOption(label);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CustomLabel
                      style={{
                        fontSize: "16px",
                        // fontWeight: "500",
                        color: neturalBlack,
                      }}
                    >
                      {label} {label === "One-time" ? "purchase" : null}
                    </CustomLabel>
                    {label === "Subscribe" && (
                      <CustomLabel
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: neturalBlack,
                          padding: "2%",
                          backgroundColor: netural50,
                          borderRadius: "12px",
                        }}
                      >
                        {sellingPlan.discount}% off
                      </CustomLabel>
                    )}
                  </div>
                  {label === "Subscribe" && (
                    <CustomLabel
                      style={{
                        fontSize: "14px",
                        color: netural400,
                      }}
                    >
                      Minimum of {sellingPlan.minimumCycle} recurring orders
                      before you can cancel.
                    </CustomLabel>
                  )}
                  {label === "Subscribe" ? (
                    <AutoShipProductPrice
                      product={product}
                      selectedVariant={selectedVariant}
                      priceStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      finalTotalStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      sellingPlan={sellingPlan}
                    />
                  ) : (
                    <ProductDetailPrice
                      product={product}
                      selectedVariant={selectedVariant}
                      priceStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      finalTotalStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      type={"sellingPlan"}
                    />
                  )}
                </div>
              </div>
              {label === selectDelivery && (
                <>
                  <div
                    style={{
                      marginTop: "4%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {quantityError && (
                      <CustomLabel
                        style={{
                          fontSize: "14px",
                          color: "red",
                          width: "100%",
                        }}
                      >
                        {quantityError}
                      </CustomLabel>
                    )}
                    <IncrementDecrementButton
                      quantityCount={quantityCount}
                      setQuantityCount={setQuantityCount}
                      minValue={minValue}
                      maxValue={maxValue}
                    />
                    <CustomLabel
                      style={{
                        fontSize: "12px",
                        color: "grey",
                        marginTop: "0%",
                      }}
                    >
                      Max quantity: 50
                    </CustomLabel>
                  </div>
                  {label === "Subscribe" && (
                    <div
                      style={{
                        marginTop: "4%",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginBottom: "2%",
                      }}
                    >
                      <Form.Group style={{ color: "#A6A6A6" }}>
                        <Form.Label
                          style={{
                            color: neturalBlack,
                          }}
                        >
                          Deliver every
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          size="sm"
                          style={{
                            borderRadius: "12px",
                            border: "1px solid #dadada",
                            fontSize: "1rem",
                          }}
                          value={minimumBillingCycle}
                          onChange={(value) => {
                            let selectedDropdownValue = value.target.value;
                            setMinimumBillingCycle(selectedDropdownValue);
                          }}
                        >
                          {billingDropDown.map((number, index) => {
                            return (
                              <option key={index} value={number}>
                                {number}{" "}
                                {sellingPlan.frequencyType === "weekly"
                                  ? "week"
                                  : "month"}
                                {"(s)"}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "4%",
                      marginLeft: "2%",
                      marginRight: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    <CustomButton
                      customStyle={{
                        marginTop: "0px",
                        borderRadius: "12px",
                        height: "50px",
                      }}
                      title={
                        label === "Subscribe" ? "Set up now" : "Add to Cart"
                      }
                      processing={isLoading}
                      handleButtonClick={() => {
                        setIsLoading(true);
                        if (quantityCount === "" || quantityCount === 0) {
                          setQuantityError("Invalid quantity");
                          setIsLoading(false);
                        } else {
                          if (isUserAuthenticated) {
                            checkValidation();
                          } else {
                            setIsLoading(false);
                            setLoginTitle(
                              "Please log in using phone number registered to proceed to checkout."
                            );
                            setIsShowLogin(true);
                          }
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {index === 0 && (
              <div
                style={
                  selectDelivery === ""
                    ? { border: "solid 1px #dadada" }
                    : { border: "solid 1px #E97101" }
                }
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailAutoShipDesign;
