import { Col, Row } from "react-bootstrap";
import { netural50, neturalBlack } from "../../utils/colors";
import { MdArrowRightAlt } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomLabel from "../shared/label/custom-label.component";
import "./home-page-company-logo.style.css";
import { Link } from "react-router-dom";

const CompanyLogoSlider = ({ logo }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div
      style={{
        marginTop: "3%",
        backgroundColor: netural50,
        marginLeft: "0.8%",
        marginRight: "0.8%",
        marginBottom: "2%",
      }}
    >
      <Row style={{ paddingTop: "3%", marginBottom: "5%" }}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <CustomLabel
            style={{
              fontSize: "24px",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Trusted business partners
          </CustomLabel>
        </Col>
        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <CustomLabel
            style={{
              textAlign: "center",
            }}
          >
            Striving together to deliver exceptional products and services to
            our customers.
          </CustomLabel>
        </Col>
        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Link className="partner-with-us-button" to={`partnerwithus`}>
            <CustomLabel
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
                display: "flex",
                width: "90%",
                color: neturalBlack
              }}
            >
              Partner with us
              <MdArrowRightAlt
                size={20}
                style={{ marginLeft: "5%" }}
                color={neturalBlack}
              />
            </CustomLabel>
          </Link>
        </Col>
      </Row>
      <div style={{ paddingBottom: "3%" }}>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          //   autoPlay={true}
          autoPlaySpeed={1000}
          keyBoardControl={false}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          //   deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          arrows={false}
        >
          {logo.map((companyLogo, index) => {
            return (
              <div className="alignment-center" key={index}>
                <img
                  src={companyLogo.imageUrl}
                  // src={`${process.env.REACT_APP_HOST_URL}${companyLogo.imageUrl}`}
                  alt={companyLogo.title}
                  style={{ marginRight: "2%", objectFit: "contain" }}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CompanyLogoSlider;
