import { Col, Container, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  netural400,
  netural50,
  neturalBlack,
  successGreen,
} from "../../utils/colors";
import { Rating } from "react-simple-star-rating";
import { BsDot } from "react-icons/bs";
import IncrementDecrementButton from "../increment-decrement-button/increment-decrement-button.component";
import Collapsible from "react-collapsible";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import Footer from "../home-page-footer/home-page-footer.component";
import { toast } from "react-toastify";
import CartToastDesign from "../toast-design/cart-toast-design.component";
import "./product-service-details.style.css";
import { useEffect, useState } from "react";
import ReviewModal from "../review-modal/review-modal.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_TO_CART,
  FETCH_PRODUCT_DETAILS_BY_ID,
} from "../../graphQL/products/products";
import CustomLabel from "../shared/label/custom-label.component";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import ProductVariant from "../products-variants/products-variants.component";
import ProductDetailPrice from "../product-price/product-detail-price.component";
import {
  isUserLoggedIn,
  selectCurrentUser,
} from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import LoginModal from "../login/login.component";
import { useDispatch } from "react-redux";
import {
  increaseUserCartCount,
  setUserAutoshipCart,
} from "../../store/cart/cart.action";
import ReviewList from "../product-detail-review-list/product-detail-review-list.component";
import {
  BUSINESS_SUBSCRIPTION,
  BUSINESS_VERIFICATION_STATUS_TYPES,
  ITEM_TYPE,
} from "../../utils/constants";
import {
  VALIDATE_QUANTITY,
  VALIDATE_QUANTITY_LIMITATION,
} from "../../graphQL/cart/cart.query";
import CustomButton from "../button/button.component";
import ProductDetailAutoShipDesign from "../product-detail-auto-ship-design/product-detail-auto-ship-design.component";

const monthlyMinimumCycleOptions = [1, 2, 3, 4, 5, 6];
const weeklyMinimumCycleOptions = [1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 24];

const ProductServiceDetail = () => {
  const params = useParams();
  const { productId } = params;
  const currentUser = useSelector(selectCurrentUser);
  const navigation = useNavigate();
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [product, setProduct] = useState({});
  const [productMedia, setProductMedia] = useState([]);
  const [productReview, setProductReview] = useState([]);
  const [quantityError, setQuantityError] = useState("");
  const minValue = 1;
  const maxValue = 50;
  const [quantityCount, setQuantityCount] = useState(minValue);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loginTitle, setLoginTitle] = useState("");
  const [isAutoship, setIsAutoship] = useState(false);
  const [sellingPlan, setSellingPlan] = useState(null);
  const [selectDelivery, setSelectDelivery] = useState("One-time");
  const [minimumBillingCycle, setMinimumBillingCycle] = useState(1);
  const [billingDropDown, setBillingDropDown] = useState([]);

  // Set the selectedVariant to the first option when the component mounts

  const getSelectedVariant = () => {
    // console.log("Called---------------", product)
    return product?.variantCombinations?.find((combination) => {
      const options = combination.combination.includes("/")
        ? combination.combination.split("/")
        : [combination.combination];
      // Check if every selected option is included in the combination options
      return Object.values(selectedOptions).every((selectedOptions) => {
        return options.includes(selectedOptions);
      });
    });
  };

  useEffect(() => {
    setSelectedVariant(getSelectedVariant());
  }, [selectedOptions]);

  useEffect(() => {
    fetchProductDetailsById({
      variables: {
        itemId: atob(productId),
        type: ITEM_TYPE.PRODUCT,
      },
    });
  }, [productId]);

  useEffect(() => {
    if (quantityCount && quantityCount !== "") {
      setQuantityError("");
    }
  }, [quantityCount]);

  const [fetchProductDetailsById, { loading: productLoading }] = useLazyQuery(
    FETCH_PRODUCT_DETAILS_BY_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchItemDetailsById) {
          let productInfo = data.fetchItemDetailsById;
          // console.log("All value-------------", productInfo);
          setProduct(productInfo);
          setProductMedia(productInfo.medias);
          setProductReview(productInfo.reviews);
          if (productInfo.autoship) {
            setIsAutoship(true);
            setSellingPlan(productInfo.sellingPlan);
            if (productInfo.sellingPlan.frequencyType === "weekly") {
              setBillingDropDown(weeklyMinimumCycleOptions);
            } else {
              setBillingDropDown(monthlyMinimumCycleOptions);
            }
          }
        }
      },
    }
  );

  const handleChangeDeviveryOption = (label) => {
    setSelectDelivery(label);
    setQuantityCount(minValue);
  };

  const displayToast = () => {
    toast(
      <CartToastDesign
        productImageURL={productMedia[0].uri}
        productName={product.title}
        productPrice={
          selectedVariant?.finalTotal
            ? selectedVariant?.finalTotal
            : product.finalTotal
        }
      />,
      {
        bodyStyle: { flexDirection: "column", alignItems: "start" },
        progressStyle: { background: successGreen },
        autoClose: "5000",
      }
    );
  };

  const [addToCart] = useMutation(ADD_TO_CART, {
    onCompleted: (data) => {
      if (data && data.addToCart.success) {
        dispatch(increaseUserCartCount(parseInt(quantityCount)));
        displayToast();
        setIsLoading(false);
      } else if (!data.addToCart.success) {
        setIsLoading(false);
        toast.error(data.addToCart.message, {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
    onError: (error) => {
      setIsLoading(false);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });
  const [validateQuantity] = useMutation(VALIDATE_QUANTITY);
  const [validateQuantityLimitation] = useMutation(
    VALIDATE_QUANTITY_LIMITATION
  );

  const handleCartClick = () => {
    addToCart({
      variables: {
        itemId: atob(productId),
        quantity: parseInt(quantityCount),
        type: !selectedVariant
          ? "Product"
          : `${"Product"},${selectedVariant.combination}`,
      },
    });
  };

  const handleAddressCheck = () => {
    // console.log("Frequency-------------", minimumBillingCycle);
    const autoShip = {
      companyName: product.companyName,
      businessId: product.businessId,
      taxRate: product.taxRate,
      type: "autoship",
      shippingCost: 4.92,
      items: [
        {
          _id: product._id,
          user_id: currentUser._id,
          sku: "",
          title: product.title,
          description: product.description,
          productDetails: product.productDetails,
          companyName: product.companyName,
          price:
            selectedVariant && selectedVariant?.combination
              ? parseFloat(selectedVariant.price).toFixed(2)
              : parseFloat(product.price).toFixed(2),
          medias: product.medias,
          discount:
            selectedVariant && selectedVariant?.combination
              ? parseFloat(selectedVariant.discount).toFixed(2)
              : parseFloat(product.discount).toFixed(2),
          finalTotal:
            selectedVariant && selectedVariant?.combination
              ? parseFloat(selectedVariant.finalTotal).toFixed(2)
              : parseFloat(product.finalTotal).toFixed(2),
          quantity: parseInt(quantityCount).toString(),
          itemType: product.itemType,
          businessId: product.businessId,
          quantityLimitation: product.quantityLimitation,
          // variants: product?.variants?.length > 0 ? product?.variants : [],
          variantCombination:
            selectedVariant && selectedVariant?.combination
              ? selectedVariant.combination
              : null,
          categories: [],
          proviceName: product.proviceName,
          autoship: isAutoship,
          billingCycle: minimumBillingCycle,
        },
      ],
      sellingPlan: {
        discount: isAutoship ? sellingPlan.discount : null,
        frequencyType: isAutoship ? sellingPlan.frequencyType : null,
        minimumCycle: isAutoship ? sellingPlan.minimumCycle : null,
        planName: isAutoship ? sellingPlan.planName : null,
        _id: isAutoship ? sellingPlan._id : null,
      },
    };
    //console.log("PRODUCT CLIcKED", autoShip, product, selectedVariant);
    dispatch(setUserAutoshipCart(autoShip));
    setIsLoading(false);
    navigation("/buy?type=autoship", { state: product.businessId });
  };

  const checkValidation = async () => {
    let restrictionObject = {
      cartQuantity: parseInt(quantityCount),
      itemId: atob(productId),
      variantCombination:
        selectedVariant && selectedVariant?.combination
          ? selectedVariant.combination
          : null,
      itemType: "Product",
    };
    let result = await validateQuantityLimitation({
      variables: { itemQuantityLimitation: [restrictionObject] },
    });
    if (result.data.validateQuantityLimitation.success) {
      let itemObject = {
        quantity: quantityCount.toString(),
        itemId: atob(productId),
        variantCombination:
          selectedVariant && selectedVariant?.combination
            ? selectedVariant.combination
            : null,
        type: "Product",
      };

      let response = await validateQuantity({
        variables: {
          itemQuantity: [itemObject],
        },
      });

      if (response.data.validateQuantity.success) {
        if (selectDelivery === "Subscribe") {
          handleAddressCheck();
        } else {
          handleCartClick();
        }
      } else {
        setIsLoading(false);
        const { itemName, availableQuantity } =
          response.data.validateQuantity.itemAvaibility[0];
        const errorMessage =
          availableQuantity === null
            ? itemName
            : `${itemName} available  quantity is: ${availableQuantity}`;
        toast.warn(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      setIsLoading(false);
      toast.warn(
        `Quantity limitation Error: ${result.data.validateQuantityLimitation.itemAvaibility[0].itemName} available quantity is: ${result.data.validateQuantityLimitation.itemAvaibility[0].availableQuantity}`,
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };
  return (
    <>
      <Container style={{ width: "99%", marginTop: "3%" }}>
        {productLoading ? (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpinner overlay />
          </div>
        ) : (
          <>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col lg={10} md={10} sm={12} xs={12}>
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="section1-scroll"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "3%",
                      }}
                    >
                      <Link to={"/"}>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: netural400,
                          }}
                        >
                          Home
                        </CustomLabel>{" "}
                      </Link>
                      <CustomLabel
                        style={{ marginLeft: "1%", marginRight: "1%" }}
                      >
                        /
                      </CustomLabel>
                      <Link to={"/products"}>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: netural400,
                          }}
                        >
                          Products
                        </CustomLabel>{" "}
                      </Link>
                      <CustomLabel
                        style={{ marginLeft: "1%", marginRight: "1%" }}
                      >
                        /
                      </CustomLabel>
                      <Link>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {product.title}
                        </CustomLabel>{" "}
                      </Link>
                    </div>
                    <Carousel
                      emulateTouch
                      autoPlay
                      // infiniteLoop={false}
                      showArrows={false}
                      showIndicators={false}
                      showStatus={false}
                      dynamicHeight={false}
                    >
                      {productMedia.map((data, index) => {
                        return (
                          <div key={index}>
                            <img
                              alt={product.title}
                              // src={"../../images/Product-Image.png"}
                              src={`${process.env.REACT_APP_HOST_URL}${data.uri}`}
                              className="product-detail-image"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                    <Col
                      xl={12}
                      lg={12}
                      md={0}
                      sm={0}
                      xs={0}
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "3%",
                      }}
                      className="description-section-for-large"
                    >
                      <div
                        style={{
                          borderTop: "1px solid #dadada",
                          paddingTop: "3%",
                        }}
                      >
                        <Collapsible
                          triggerStyle={{
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                            display: "flex",
                          }}
                          trigger={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CustomLabel
                                style={{
                                  width: "98%",
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >
                                Description
                              </CustomLabel>
                              <IoChevronDownOutline />
                            </div>
                          }
                          triggerWhenOpen={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CustomLabel
                                style={{
                                  width: "98%",
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >
                                Description
                              </CustomLabel>
                              <IoChevronUpOutline />
                            </div>
                          }
                        >
                          <div
                            style={{
                              marginTop: "3%",
                            }}
                          >
                            <CustomLabel style={{ textAlign: "justify" }}>
                              {product.description}
                            </CustomLabel>
                          </div>
                        </Collapsible>
                      </div>
                      <div
                        style={{
                          marginTop: "3%",
                          borderTop: "1px solid #dadada",
                          borderBottom: "1px solid #dadada",
                          paddingTop: "3%",
                          paddingBottom: "3%",
                        }}
                      >
                        <Collapsible
                          triggerStyle={{
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                            display: "flex",
                          }}
                          trigger={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CustomLabel
                                style={{
                                  width: "98%",
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >
                                Product details
                              </CustomLabel>
                              <IoChevronDownOutline />
                            </div>
                          }
                          triggerWhenOpen={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CustomLabel
                                style={{
                                  width: "98%",
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >
                                Product details
                              </CustomLabel>
                              <IoChevronUpOutline />
                            </div>
                          }
                        >
                          <div
                            style={{
                              marginTop: "3%",
                            }}
                          >
                            <CustomLabel style={{ textAlign: "justify" }}>
                              {product.productDetails}
                            </CustomLabel>
                          </div>
                        </Collapsible>
                      </div>
                      {product.totalReview !== "0" && (
                        <div
                          style={{
                            marginTop: "3%",
                            borderBottom: "1px solid #dadada",
                            paddingTop: "3%",
                            paddingBottom: "3%",
                          }}
                        >
                          <Collapsible
                            triggerStyle={{
                              fontSize: "16px",
                              fontWeight: "500",
                              width: "100%",
                              display: "flex",
                            }}
                            trigger={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomLabel
                                  style={{
                                    width: "98%",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Reviews ({product.totalReview})
                                </CustomLabel>
                                <Rating
                                  allowFraction
                                  size={20}
                                  initialValue={product.totalRating}
                                  readonly
                                  fillColor={neturalBlack}
                                />
                                <IoChevronDownOutline
                                  style={{ marginLeft: "1%" }}
                                />
                              </div>
                            }
                            triggerWhenOpen={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomLabel
                                  style={{
                                    width: "98%",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Reviews ({product.totalReview})
                                </CustomLabel>
                                <Rating
                                  allowFraction
                                  size={20}
                                  initialValue={product.totalRating}
                                  readonly
                                  fillColor={neturalBlack}
                                />
                                <IoChevronUpOutline
                                  style={{ marginLeft: "1%" }}
                                />
                              </div>
                            }
                          >
                            <ReviewList reviews={productReview} />
                            {parseInt(product?.totalReview) ===
                            productReview?.length ? (
                              <div
                                style={{
                                  marginTop: "7%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: neturalBlack,
                                    marginLeft: "10%",
                                    marginRight: "10%",
                                    width: "100%",
                                    backgroundColor: netural50,
                                    borderRadius: "12px",
                                  }}
                                  onClick={() => {
                                    // setShowReviewModal(true);
                                    if (!isUserAuthenticated) {
                                      setLoginTitle(
                                        "Please log in using phone number registered to read more reviews."
                                      );
                                      setIsShowLogin(true);
                                    } else {
                                      setShowReviewModal(true);
                                    }
                                  }}
                                >
                                  Read more reviews
                                </button>
                              </div>
                            ) : null}
                          </Collapsible>
                        </div>
                      )}
                    </Col>
                  </Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="offset-sm-1 section2-scroll"
                  >
                    <div>
                      <CustomLabel
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: netural400,
                          width: "100%",
                        }}
                      >
                        {product.companyName}
                      </CustomLabel>
                      <CustomLabel
                        style={{
                          fontSize: "32px",
                          fontWeight: "bold",
                          color: neturalBlack,
                        }}
                      >
                        {product.title}
                      </CustomLabel>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          allowFraction
                          size={20}
                          initialValue={product.totalRating}
                          readonly
                          fillColor={neturalBlack}
                        />
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            color: neturalBlack,
                            marginLeft: "1%",
                          }}
                        >
                          {" "}
                          ({product.totalRating} stars){" "}
                        </CustomLabel>
                        <BsDot size={18} />
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            color: neturalBlack,
                            marginLeft: "1%",
                          }}
                        >
                          {" "}
                          {product.totalReview}
                        </CustomLabel>
                      </div>
                      <div style={{ marginTop: "1%" }}>
                        <ProductDetailPrice
                          product={product}
                          selectedVariant={selectedVariant}
                          priceStyle={{
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                          finalTotalStyle={{
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                          type={"product"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "4%",
                        }}
                      >
                        {isAutoship && product.variants?.length > 0 && (
                          <CustomLabel
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: neturalBlack,
                            }}
                          >
                            1. Pick your favourite option(s)
                          </CustomLabel>
                        )}
                        <ProductVariant
                          product={product}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                        />
                      </div>
                      {(product.subscriptionName ===
                        BUSINESS_SUBSCRIPTION.PRO_PLAN ||
                        product.subscriptionName ===
                          BUSINESS_SUBSCRIPTION.PREMIUM_PLAN) &&
                      (product.businessVerificationStatus ===
                        BUSINESS_VERIFICATION_STATUS_TYPES.APPROVED ||
                        product.businessVerificationStatus ===
                          BUSINESS_VERIFICATION_STATUS_TYPES.REVERIFYING) ? (
                        <>
                          {!isAutoship && (
                            <div
                              style={{
                                marginTop: "4%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {quantityError && (
                                <CustomLabel
                                  style={{
                                    fontSize: "14px",
                                    color: "red",
                                    width: "100%",
                                  }}
                                >
                                  {quantityError}
                                </CustomLabel>
                              )}
                              <IncrementDecrementButton
                                quantityCount={quantityCount}
                                setQuantityCount={setQuantityCount}
                                minValue={minValue}
                                maxValue={maxValue}
                              />
                              <CustomLabel
                                style={{
                                  fontSize: "12px",
                                  color: "grey",
                                  marginTop: "0%",
                                }}
                              >
                                Max quantity: 50
                              </CustomLabel>
                            </div>
                          )}
                          {isAutoship ? (
                            <ProductDetailAutoShipDesign
                              product={product}
                              sellingPlan={sellingPlan}
                              selectDelivery={selectDelivery}
                              minimumBillingCycle={minimumBillingCycle}
                              setMinimumBillingCycle={setMinimumBillingCycle}
                              billingDropDown={billingDropDown}
                              quantityCount={quantityCount}
                              setQuantityCount={setQuantityCount}
                              selectedVariant={selectedVariant}
                              quantityError={quantityError}
                              setQuantityError={setQuantityError}
                              minValue={minValue}
                              maxValue={maxValue}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              isUserAuthenticated={isUserAuthenticated}
                              checkValidation={checkValidation}
                              setLoginTitle={setLoginTitle}
                              setIsShowLogin={setIsShowLogin}
                              handleChangeDeviveryOption={
                                handleChangeDeviveryOption
                              }
                            />
                          ) : (
                            <div
                              style={{ marginTop: "4%" }}
                              className="add-to-cart-btn"
                            >
                              <CustomButton
                                customStyle={{
                                  marginTop: "0px",
                                  borderRadius: "12px",
                                  height: "50px",
                                }}
                                title={"Add to Cart"}
                                processing={isLoading}
                                handleButtonClick={() => {
                                  setIsLoading(true);
                                  if (
                                    quantityCount === "" ||
                                    quantityCount === 0
                                  ) {
                                    setQuantityError("Invalid quantity");
                                    setIsLoading(false);
                                  } else {
                                    if (isUserAuthenticated) {
                                      checkValidation();
                                    } else {
                                      setIsLoading(false);
                                      setLoginTitle(
                                        "Please log in using phone number registered to proceed to checkout."
                                      );
                                      setIsShowLogin(true);
                                    }
                                  }
                                }}
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={0}
                lg={0}
                md={5}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "8%",
                }}
                className="description-section-for-small"
              >
                <div
                  style={{ borderTop: "1px solid #dadada", paddingTop: "3%" }}
                >
                  <Collapsible
                    triggerStyle={{
                      fontSize: "16px",
                      fontWeight: "500",
                      width: "100%",
                      display: "flex",
                    }}
                    trigger={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Description
                        </CustomLabel>
                        <IoChevronDownOutline />
                      </div>
                    }
                    triggerWhenOpen={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Description
                        </CustomLabel>
                        <IoChevronUpOutline />
                      </div>
                    }
                  >
                    <div
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel style={{ textAlign: "justify" }}>
                        {product.description}
                      </CustomLabel>
                    </div>
                  </Collapsible>
                </div>
                <div
                  style={{
                    marginTop: "3%",
                    borderTop: "1px solid #dadada",
                    borderBottom: "1px solid #dadada",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                  }}
                >
                  <Collapsible
                    triggerStyle={{
                      fontSize: "16px",
                      fontWeight: "500",
                      width: "100%",
                      display: "flex",
                    }}
                    trigger={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Product details
                        </CustomLabel>
                        <IoChevronDownOutline />
                      </div>
                    }
                    triggerWhenOpen={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Product details
                        </CustomLabel>
                        <IoChevronUpOutline />
                      </div>
                    }
                  >
                    <div
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel style={{ textAlign: "justify" }}>
                        {product.productDetails}
                      </CustomLabel>
                    </div>
                  </Collapsible>
                </div>
                {product.totalReview !== "0" && (
                  <div
                    style={{
                      marginTop: "3%",
                      borderBottom: "1px solid #dadada",
                      paddingTop: "3%",
                      paddingBottom: "3%",
                    }}
                  >
                    <Collapsible
                      triggerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "100%",
                        display: "flex",
                      }}
                      trigger={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Reviews ({product.totalReview})
                          </CustomLabel>
                          <Rating
                            allowFraction
                            size={20}
                            initialValue={product.totalRating}
                            readonly
                            fillColor={neturalBlack}
                          />
                          <IoChevronDownOutline style={{ marginLeft: "1%" }} />
                        </div>
                      }
                      triggerWhenOpen={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Reviews ({product.totalReview})
                          </CustomLabel>
                          <Rating
                            allowFraction
                            size={20}
                            initialValue={product.totalRating}
                            readonly
                            fillColor={neturalBlack}
                          />
                          <IoChevronUpOutline style={{ marginLeft: "1%" }} />
                        </div>
                      }
                    >
                      <ReviewList reviews={productReview} />
                      {parseInt(product?.totalReview) ===
                      productReview?.length ? (
                        <div
                          style={{
                            marginTop: "7%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: neturalBlack,
                              marginLeft: "10%",
                              marginRight: "10%",
                              width: "100%",
                              backgroundColor: netural50,
                              borderRadius: "12px",
                            }}
                            onClick={() => {
                              // setShowReviewModal(true);
                              if (!isUserAuthenticated) {
                                setLoginTitle(
                                  "Please log in using phone number registered to read more reviews."
                                );
                                setIsShowLogin(true);
                              } else {
                                setShowReviewModal(true);
                              }
                            }}
                          >
                            Read more reviews
                          </button>
                        </div>
                      ) : null}
                    </Collapsible>
                  </div>
                )}
              </Col>
              <Col lg={5} md={5} sm={12} xs={12}></Col>
            </Row>
            <LoginModal
              show={isShowLogin}
              onHide={() => {
                setIsShowLogin(false);
              }}
              handleSignInClickLogin={() => {
                setIsShowLogin(true);
              }}
              title={loginTitle}
            />
            {showReviewModal && (
              <ReviewModal
                show={showReviewModal}
                onHide={() => {
                  setShowReviewModal(false);
                }}
                reviewList={productReview}
                item_id={atob(productId)}
                totalReview={product.totalReview}
              />
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ProductServiceDetail;
