import _ from "lodash";

export const APPLE_STORE_URL = "https://apps.apple.com/us/app/my/id1603899001";
export const GOOGLE_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
export const BUSINESS_SUBSCRIPTION = {
  FREE_PLAN: "Free Plan",
  PRO_PLAN: "Pro Plan",
  PREMIUM_PLAN: "Premium Plan",
};
/**
 * Business Verification status
 * 1)Pending:- User Just created Business Account and needs to do verification
 * 2)Processing:- User has submitted verification form
 * 3)Approved :- Business Account is verified
 * 4)Rejected :- Business Verification is failed due to wrong info
 * 5)Reverifying:- Business was approved and then business update some information and send it for verifiying
 **/
export const BUSINESS_VERIFICATION_STATUS_TYPES = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  REVERIFYING: "Reverifying",
};

export const returnPaginationRange = (totalPage,page, siblings, limit) =>{

  let totalPageNoInArray = 5 + siblings;
  if(totalPageNoInArray >= totalPage){
    return _.range(1, totalPage+1);
  }
  let leftSiblingIndex =  Math.max(page - siblings, 1);
  let rightSiblingIndex =  Math.min(page + siblings, totalPage);
  
  let showLeftDots = leftSiblingIndex > 2;
  let showRightDots = rightSiblingIndex < totalPage -  2;

  if(!showLeftDots && showRightDots){
    let leftItemCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemCount+1);

    return [...leftRange, "...", totalPage];
  }else if(showLeftDots && !showRightDots){
    let rightItemCount =  3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemCount + 1, totalPage + 1);
    
    return [1, "...", ...rightRange];
  }else{
    let middleRange = _.range(leftSiblingIndex, rightSiblingIndex + 1);

    return [1, "...", ...middleRange, "...", totalPage];
  }



}

/**
 * Item types indicating the type of item.
 * 
 * @constant
 * @type {Object}
 * @property {string} PRODUCT - Indicates that the item is a product.
 * @property {string} SERVICE - Indicates that the item is a service.
 */
export const ITEM_TYPE = {
  PRODUCT: "Product",
  SERVICE: "Service"
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
