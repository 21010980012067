import { Col, Row } from "react-bootstrap";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../utils/constants";
import CustomLabel from "../shared/label/custom-label.component";
import './home-page-download.style.css'

const DownloadApp = () => {
  return (
    <div style={{ marginTop: "3%", marginBottom: "2%" }}>
      <Row style={{ width: "100%" }}>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              className="background-image"
              src="./images/iPhone 16.svg"
              alt="mobile image"
            />
          </div>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="download-section-2"
          style={{ marginBottom: '3%' }}
        >
          <div
            className="download-section-3"
          >
            <img
              src="./images/FinalLogo.svg"
              alt="logo"
              style={{ width: "10%" }}
            />
            <div>
              <CustomLabel style={{ fontSize: "24px", fontWeight: "600" }}>
                Download Free App!
              </CustomLabel>
            </div>
            <div>
              <CustomLabel>
                Available for iOS and Adroid.
              </CustomLabel>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3%",
              }}
            >
              <a
                href={GOOGLE_STORE_URL}
                style={{ marginRight: "3%" }}
                target="_blank"
              >
                <img src="./images/Badge.png" alt="" />
              </a>
              <a
                href={APPLE_STORE_URL}
                target="_blank"
              >
                <img src="./images/Frame 11.png" alt="" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadApp;
