import HomePage from "../../components/homePage/home-page.component";

const Home = () => {

  return (
    <div>
      <HomePage />
    </div>
  );
};

export default Home;
