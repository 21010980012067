import { Fragment } from "react";
import CustomLabel from "../shared/label/custom-label.component";
import OrderSummaryProductList from "../order-summary-product-list/order-summary-product-list.component";
import OrderSummaryPricing from "../order-summary-pricing/order-summary-pricing.component";

const OrderSummary = ({
  products,
  itemType,
  pricing,
  selectedCheckoutCart,
  promotionalCode
}) => {
  return (
    <Fragment>
      <CustomLabel style={{ fontSize: "18px", fontWeight: "bold" }}>
        {itemType === "service" ? "Booking summary" : "Order summary"}
      </CustomLabel>
      <div
        style={{
          maxHeight: "30vh",
          overflowY: "auto",
        }}
      >
        {products?.map((product, index) => {
          return (
            <OrderSummaryProductList
              key={index}
              product={product}
              index={index}
              itemType={itemType}
            />
          );
        })}
      </div>
      <div style={{ borderTop: "1px solid #dadada", marginTop: "5%" }}></div>
      <OrderSummaryPricing
        itemType={itemType}
        pricing={pricing}
        selectedCheckoutCart={selectedCheckoutCart}
        promotionalCode={promotionalCode}
      />
    </Fragment>
  );
};

export default OrderSummary;
